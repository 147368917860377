import {useEffect, useRef, useState} from "react";

import {Icon, IconTypes} from "./index";

import "../styles/AudioPlayer.css";

const convertToTime = (duration) => {
    const numMinutes = Math.floor(duration / 60);
    const numSeconds = Math.floor(duration % 60);

    const minutes = (numMinutes < 10 ? "0" : "") + numMinutes;
    const seconds = (numSeconds < 10 ? "0" : "") + numSeconds;

    return minutes + ":" + seconds;
};

export const AudioPlayer = ({audioFile, backgroundColor, durationSeconds, title, windowSize}) => {
    const audioRef = useRef(null);
    const progressBarRef = useRef(null);
    const [progressBarWidth, setProgressBarWidth] = useState(0);
    const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0);
    const [isPlaying, setPlaying] = useState(false);

    useEffect(() => {
        if (progressBarRef?.current) {
            setProgressBarWidth(progressBarRef?.current?.clientWidth);
        }
    }, [windowSize]);

    const onPlaying = () => {
        setCurrentTimeSeconds(audioRef.current.currentTime);
    };

    useEffect(() => {
        if (audioRef?.current) {
            if (isPlaying) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    return (
        <div className="component audio-player" style={{backgroundColor: `var(--color-${backgroundColor})`}}>
            <div className="audio-player-left-col">
                <div className="audio-title gt-america-medium-21-21">{title}</div>
                <div className="playback gt-america-medium-8-21">
                    <div className="current-time">{convertToTime(currentTimeSeconds)}</div>
                    <div className="progress-bar" ref={progressBarRef}>
                        <div className="current-progress" style={{width: (currentTimeSeconds / durationSeconds) * progressBarWidth}}></div>
                    </div>
                    <div className="total-time">{convertToTime(durationSeconds)}</div>
                </div>
            </div>
            <div className="audio-player-right-col" onClick={() => setPlaying(!isPlaying)}>
                <div className="darken" style={{opacity: isPlaying ? 0.3 : 0}}></div>
                <audio src={audioFile} ref={audioRef} onTimeUpdate={onPlaying} preload="auto" />
                <Icon type={isPlaying ? IconTypes.PAUSE_BUTTON : IconTypes.PLAY_BUTTON} />
            </div>
        </div>
    );
};
