import { useEffect, useRef, useState } from "react";

import { BrianaMendez, LouHaidous, Wondrosa } from "../audio";
import { AudioPlayer, FadeInSlideUp, Icon, IconTypes, SectionIntro, StaggerFromBaseline, ThoughtBubble, TrendWrapper } from "../components";
import {
    ComedyKeepsItsCrown, ComedyKeepsItsCrownMobile, Headphones,
    RelevanceDrivesResonance, RelevanceDrivesResonanceMobile,
    RepresentationMatters, RepresentationMattersMobile, Section01Background,
    TempTheNewListener, TheStreamingEffect, TheStreamingEffectMobile,
} from "../images";
import { Breakpoints, Sections, wait } from "../utils";

import "../styles/Section01.css";

const thoughtBubbleForegroundBgColor = "neon-indigo";
const thoughtBubbleBgFill = "gradient-plum-plum";

const LeftGraph = ({ startAnim }) => {
    const [opacityRow1, setOpacityRow1] = useState(0);
    const [opacityRow2, setOpacityRow2] = useState(0);
    const [opacityRow3, setOpacityRow3] = useState(0);
    const [opacityRow4, setOpacityRow4] = useState(0);
    const [opacityFooter, setOpacityFooter] = useState(0);

    useEffect(() => {
        const waitTime = 0.3;
        const anim = async () => {
            await wait(waitTime);
            setOpacityRow1(1);
            await wait(waitTime);
            setOpacityRow2(1);
            await wait(waitTime);
            setOpacityRow3(1);
            await wait(waitTime);
            setOpacityRow4(1);
            await wait(waitTime);
            setOpacityFooter(1);
        };

        if (startAnim) {
            anim();
        }
    }, [startAnim]);

    return (
        <div className="left-graph">
            <div className="header-background"></div>
            <div className="header gt-america-bold-28-43">The O.G. Listener</div>
            <div className="table">
                <div className="row" style={{ opacity: opacityRow1 }}>
                    <div className="col-50">
                        <div className="stat gt-america-medium-44-48">6+</div>
                        <div className="caption gt-america-bold-11-13">Years Listening</div>
                    </div>
                    <div className="col-50">
                        <div className="stat gt-america-medium-44-48">7.1</div>
                        <div className="caption gt-america-bold-11-13">Hours Per Week</div>
                    </div>
                </div>
                <div className="row" style={{ opacity: opacityRow2 }}>
                    <div className="col-30">
                        <div className="label gt-america-bold-28-30">Mature</div>
                    </div>
                    <div className="col-70">
                        <div className="stat gt-america-medium-44-48">63%</div>
                        <div className="caption gt-america-bold-11-13">Are 35-54 Years Old</div>
                    </div>
                </div>
                <div className="row" style={{ opacity: opacityRow3 }}>
                    <div className="col-30">
                        <div className="label gt-america-bold-28-30">More Male</div>
                    </div>
                    <div className="col-70">
                        <div>
                            <div className="stat gt-america-medium-44-48">71%</div>
                            <div className="caption gt-america-bold-11-13">Male</div>
                        </div>
                        <div>
                            <div className="stat gt-america-medium-44-48">29%</div>
                            <div className="caption gt-america-bold-11-13">Female</div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ opacity: opacityRow4 }}>
                    <div className="col-100">
                        <div className="stat gt-america-medium-44-48">$101,331</div>
                        <div className="caption gt-america-bold-11-13">Mean Household Income</div>
                    </div>
                </div>
            </div>
            <div className="footnote gt-america-regular-6-10" style={{ opacity: opacityFooter }}>
                SiriusXM Media, Publicis Media, and Signal Hill Insights, Podcast Consumer Journey Study, Q4 2021
            </div>
        </div>
    );
};

const RightGraph = ({ startAnim }) => {
    const [opacityRow1, setOpacityRow1] = useState(0);
    const [opacityRow2, setOpacityRow2] = useState(0);
    const [opacityRow3, setOpacityRow3] = useState(0);
    const [opacityRow4, setOpacityRow4] = useState(0);
    const [opacityRow5, setOpacityRow5] = useState(0);

    useEffect(() => {
        const waitTime = 0.3;
        const anim = async () => {
            await wait(waitTime);
            setOpacityRow1(1);
            await wait(waitTime);
            setOpacityRow2(1);
            await wait(waitTime);
            setOpacityRow3(1);
            await wait(waitTime);
            setOpacityRow4(1);
            await wait(waitTime);
            setOpacityRow5(1);
        };

        if (startAnim) {
            anim();
        }
    }, [startAnim]);

    return (
        <div className="right-graph">
            <div className="header-background"></div>
            <div className="header gt-america-bold-28-43">The New Listener</div>
            <div className="table">
                <div className="row" style={{ opacity: opacityRow1 }}>
                    <div className="col-50">
                        <div className="stat gt-america-medium-44-48">&lt;1</div>
                        <div className="caption gt-america-bold-11-13">Years Listening</div>
                    </div>
                    <div className="col-50">
                        <div className="stat gt-america-medium-44-48">4.4</div>
                        <div className="caption gt-america-bold-11-13">Hours Per Week</div>
                    </div>
                </div>
                <div className="row" style={{ opacity: opacityRow2 }}>
                    <div className="col-30">
                        <div className="label gt-america-bold-28-30">Young</div>
                    </div>
                    <div className="col-70">
                        <div className="stat gt-america-medium-44-48">66%</div>
                        <div className="caption gt-america-bold-11-13">Are 18-34 Years Old</div>
                    </div>
                </div>
                <div className="row" style={{ opacity: opacityRow3 }}>
                    <div className="col-30">
                        <div className="label gt-america-bold-28-30">Even Split</div>
                    </div>
                    <div className="col-70">
                        <div>
                            <div className="stat gt-america-medium-44-48">50%</div>
                            <div className="caption gt-america-bold-11-13">Male</div>
                        </div>
                        <div>
                            <div className="stat gt-america-medium-44-48">50%</div>
                            <div className="caption gt-america-bold-11-13">Female</div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ opacity: opacityRow4 }}>
                    <div className="col-50">
                        <div className="stat gt-america-medium-44-48">35.7%</div>
                        <div className="caption gt-america-bold-11-13">More Likely<br />to be Black</div>
                    </div>
                    <div className="col-50">
                        <div className="stat gt-america-medium-44-48">20%</div>
                        <div className="caption gt-america-bold-11-13">More Likely<br />to be Hispanic</div>
                    </div>
                </div>
                <div className="row" style={{ opacity: opacityRow5 }}>
                    <div className="col-100">
                        <div className="stat gt-america-medium-44-48">$77,272</div>
                        <div className="caption gt-america-bold-11-13">Mean Household Income</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Trend01DiverseListener = ({ scrollPos, windowSize }) => {
    const tablesRef = useRef(null);
    const [startGraphAnim, setStartGraphAnim] = useState(false);

    useEffect(() => {
        if (
            scrollPos && windowSize && tablesRef?.current &&
            tablesRef.current.getBoundingClientRect().top < (windowSize.height * 0.75)
        ) {
            setStartGraphAnim(true);
        }
    }, [scrollPos, windowSize]);

    return (
        <FadeInSlideUp
            {...{ scrollPos, windowSize }}
            className="trend-01-diverse-listener container"
        >
            <div className="left-col gt-america-bold-80-76">
                Introducing a Diverse Listener
            </div>
            <div className="right-col" ref={tablesRef}>
                <LeftGraph startAnim={startGraphAnim} />
                <RightGraph startAnim={startGraphAnim} />
            </div>
        </FadeInSlideUp>
    );
}

const Trend01ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="trend-01-thought-bubble thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="Representation matters."
            description="Diverse listeners are being driven to the platform by diverse content. In fact, over half of Black listeners say that it is important that the content they listen to is hosted by a Black creator, and 37% of Latinos say they would be very likely to purchase a brand if they heard the advertisement from a Latino host."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            image={RepresentationMatters}
            mobileImage={RepresentationMattersMobile}
            backgroundFill={thoughtBubbleBgFill}
        />
        <div className="thought-bubble-footer">
            <div className="footnote gt-america-regular-6-10">
                <div>SiriusXM Media, Mindshare, and Edison Research, Black Podcast Report, November 2021</div>
                <div>Edison Research, Latino Podcast Listener Report, 2021</div>
            </div>
            <div className="audio-player-wrapper">
                <AudioPlayer
                    {...{ windowSize }}
                    audioFile={BrianaMendez}
                    backgroundColor="neon-plum"
                    durationSeconds={34}
                    title="Briana Mendez, Listener"
                />
            </div>
        </div>
    </FadeInSlideUp>
);

const ColChart = ({ data, scrollPos, side, windowSize }) => {
    const rowHeight = 80;
    const rowHeightWithoutPadding = 46;

    const getRowPos = (rowNum) => {
        const index = rowNum - 1;
        return (rowHeight * index) - (index <= 1 ? 1 : index);
    };

    const chartRef = useRef(null);
    const [startAnim, setStartAnim] = useState(false);
    const defaultLeftPos = -1;
    const row1Pos = { left: defaultLeftPos, top: getRowPos(1) };
    const [row2Pos, setRow2Pos] = useState({ left: defaultLeftPos, top: getRowPos(2) });
    const [row3Pos, setRow3Pos] = useState({ left: defaultLeftPos, top: getRowPos(3) });
    const [row4Pos, setRow4Pos] = useState({ left: defaultLeftPos, top: getRowPos(4) });
    const [row5Pos, setRow5Pos] = useState({ left: defaultLeftPos, top: getRowPos(5) });

    useEffect(() => {
        if (
            !startAnim && scrollPos && windowSize && chartRef?.current &&
            chartRef.current.getBoundingClientRect().bottom < (windowSize.height * 0.9)
        ) {
            setStartAnim(true);
        }
    }, [scrollPos, windowSize]);

    useEffect(() => {
        const leftChartAnim = async () => {
            // 3d pop out rows moving up
            setRow3Pos({ left: -20, top: row3Pos.top - 20 });
            setRow5Pos({ left: -20, top: row5Pos.top - 20 });

            await wait(0.75);

            // move "News & Politics" -- 3 to 2
            setRow3Pos({ left: defaultLeftPos, top: getRowPos(2) });

            // move "Sports" -- 2 to 3
            setRow2Pos({ ...row2Pos, top: getRowPos(3) });

            // move "True Crime" -- 4 to 5
            setRow4Pos({ ...row4Pos, top: getRowPos(5) });

            // move "Science & Technology" -- 5 to 4
            setRow5Pos({ left: defaultLeftPos, top: getRowPos(4) });
        };

        const rightChartAnim = async () => {
            // 3d pop out rows moving up
            setRow3Pos({ left: -20, top: row3Pos.top - 20 });
            setRow5Pos({ left: -20, top: row5Pos.top - 20 });

            await wait(0.75);

            // move "News & Politics" -- 3 to 5
            setRow3Pos({ left: defaultLeftPos, top: getRowPos(5) });

            // move "Celebrity Conversation" -- 5 to 3
            setRow5Pos({ left: defaultLeftPos, top: getRowPos(3) });
        };

        if (startAnim) {
            if (side === "left") {
                leftChartAnim();
            } else if (side === "right") {
                rightChartAnim();
            }
        }
    }, [startAnim]);

    return (
        <div className={`col-chart ${side}-side`} ref={chartRef}>
            <div className="header">
                <div className="bottom-layer"></div>
                <div className="top-layer">
                    <div className="header-title gt-america-bold-28-30">
                        {side === "left" && "The O.G. Listener"}
                        {side === "right" && "The New Listener"}
                    </div>
                </div>
            </div>
            <div className="chart-rows" style={{ height: (rowHeight * data.length) - (data.length + 1) }}>
                {data.map((dataRow, index) => {
                    const rowPos = [row1Pos, row2Pos, row3Pos, row4Pos, row5Pos];
                    return (
                        <div
                            className="row"
                            key={index}
                            style={{ ...rowPos[index], height: rowHeightWithoutPadding }}
                        >
                            <div className="title gt-america-regular-21-24">{dataRow.title}</div>
                            <div className={`arrow ${dataRow.arrow}`}>
                                {dataRow.arrow === "up" && (
                                    <Icon height={16} type={IconTypes.UP_ARROW} />
                                )}
                                {dataRow.arrow === "down" && (
                                    <Icon fillColor="black" height={29} type={IconTypes.DOWN_ARROW} />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const Trend02EscapismFTW = ({ scrollPos, windowSize }) => {
    const leftColData = [
        { title: "Comedy" },
        { title: "Sports" },
        { title: <span>News & <br />Politics</span>, arrow: "up" },
        { title: "True Crime", arrow: "down" },
        { title: <span>Science & <br />Technology</span>, arrow: "up" },
    ];
    const rightColData = [
        { title: "Comedy" },
        { title: "True Crime" },
        { title: <span>News & <br />Politics</span>, arrow: "down" },
        { title: "Sports" },
        { title: <span>Celebrity <br />Conversation</span>, arrow: "up" },
    ];

    return (
        <FadeInSlideUp
            {...{ scrollPos, windowSize }}
            className="trend-02-escapism-ftw container"
        >
            <div className="left-col">
                <div className="title gt-america-bold-80-76">Escapism FTW</div>
            </div>
            <div className="right-col">
                <div className="left-image">
                    <ColChart
                        {...{ scrollPos, windowSize }}
                        data={leftColData}
                        side="left"
                    />
                    <div className="footnote gt-america-regular-6-10">
                        SiriusXM Media, Publicis Media, and Signal Hill Insights, Podcast Consumer Journey Study, Q4 2021
                    </div>
                </div>
                <div className="right-image">
                    <ColChart
                        {...{ scrollPos, windowSize }}
                        data={rightColData}
                        side="right"
                    />
                </div>
            </div>
        </FadeInSlideUp>
    );
};

const Trend02ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="Comedy keeps its crown."
            description="New listeners are lifting a variety of genres, with a tendency toward stories that provide a little escape from reality (a little celeb gossip, anyone?). But no matter how long you’ve been listening, laughter never goes out of style."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={ComedyKeepsItsCrown}
            mobileImage={ComedyKeepsItsCrownMobile}
        />
    </FadeInSlideUp>
);

const Trend03PlayingTheField = ({ scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="trend-03-playing-the-field container"
    >
        <div className="left-col">
            <div className="title gt-america-bold-80-76">Playing{' '}<br />the Field</div>
        </div>
        <div className="right-col">
            <img src={TempTheNewListener} alt="" />
            <div className="stat-value gt-america-regular-168-168">
                <StaggerFromBaseline {...{ scrollPos, windowSize }} text="36%" />
            </div>
            <div className="stat-caption gt-america-regular-32-40">more likely to say they hop from one podcast to another than OG listeners</div>
            <div className="footnote gt-america-regular-6-10">SiriusXM Media, Publicis Media, and Signal Hill Insights, Podcast Consumer Journey Study, Q4 2021</div>
        </div>
    </FadeInSlideUp>
);

const Trend03ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="The streaming effect."
            description="On-demand content has drastically changed our consumption behaviors—and it’s bleeding over into podcasts, too. New listening is more fleeting and driven by specific interests or topics. Luckily, we have the technology to let advertisers content-hop right along with them."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={TheStreamingEffect}
            mobileImage={TheStreamingEffectMobile}
        />
    </FadeInSlideUp>
);

const Trend04AdvertisersWelcome = ({ scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="trend-04-advertisers-welcome container"
    >
        <div className="left-col">
            <div className="title gt-america-bold-80-76">Advertisers Welcome</div>
            <img src={TempTheNewListener} alt="" />
            <div className="stat-value gt-america-regular-192-192">
                <StaggerFromBaseline {...{ scrollPos, windowSize }} text="19%" />
            </div>
            <div className="stat-caption gt-america-regular-32-40">more likely than OG listeners to say that non host-read ads are a good fit with the podcasts they listen to</div>
        </div>
        <div className="right-col">
            <div className="row">
                <AudioPlayer
                    {...{ windowSize }}
                    audioFile={Wondrosa}
                    backgroundColor="neon-plum"
                    durationSeconds={34}
                    title="Announcer-read Ad, Prod. by Studio Resonate"
                />
            </div>
        </div>
    </FadeInSlideUp>
);

const Trend04ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="trend-04-thought-bubble thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="Relevance drives resonance."
            description="New listeners are becoming increasingly more accepting of various podcast ad formats, but why? Our best guess: dynamic ad insertion. DAI ensures ad experiences are more relevant and less disruptive, which means a happier listener."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={RelevanceDrivesResonance}
            mobileImage={RelevanceDrivesResonanceMobile}
        />
        <div className="audio-player-wrapper">
            <AudioPlayer
                {...{ windowSize }}
                audioFile={LouHaidous}
                backgroundColor="neon-indigo"
                durationSeconds={35}
                title="Lou Haidous, Podcast Listener"
            />
        </div>
    </FadeInSlideUp>
);

export const Section01 = ({ allSectionRefs, currentBreakpoint, scrollPos, windowSize }) => {
    const customOffsetTop = allSectionRefs[Sections.INTRO]?.current?.clientHeight;

    return (
        <div className="section-01">
            <SectionIntro
                {...{ scrollPos, windowSize }}
                backgroundColor="neon-indigo"
                backgroundImage={Section01Background}
                image3d={Headphones}
                leftColText={
                    "There’s a new listener in town. And they’re changing the " +
                    "entire outlook of the business."
                }
                rightColText={
                    "Brand new listeners are flocking to podcasting every. " +
                    "single. day. And because of this, the overall audience " +
                    "profile has shifted: they’re younger, more diverse, and " +
                    "ready to podcast-hop. With a fresh attitude toward ads and " +
                    "content, this next generation is almost single-handedly " +
                    "accelerating the evolution of modern podcasting."
                }
                sectionTitle="Listening"
            />
            <div style={{ marginTop: currentBreakpoint === Breakpoints.MOBILE ? -52 : -82 }}>
                <TrendWrapper
                    {...{ currentBreakpoint, scrollPos, windowSize }}
                    blocks={[
                        <Trend01DiverseListener {...{ scrollPos, windowSize }} />,
                        <Trend01ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                    ]}
                    tickerBackgroundColor="neon-plum-20t"
                    trendNumber="01"
                    tickerCustomOffsetTop={customOffsetTop}
                />
            </div>
            <TrendWrapper
                {...{ currentBreakpoint, scrollPos, windowSize }}
                blocks={[
                    <Trend02EscapismFTW {...{ scrollPos, windowSize }} />,
                    <Trend02ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                ]}
                tickerBackgroundColor="neon-plum-20t"
                trendNumber="02"
                tickerCustomOffsetTop={customOffsetTop}
            />
            <TrendWrapper
                {...{ currentBreakpoint, scrollPos, windowSize }}
                blocks={[
                    <Trend03PlayingTheField {...{ scrollPos, windowSize }} />,
                    <Trend03ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                ]}
                tickerBackgroundColor="neon-plum-20t"
                trendNumber="03"
                tickerCustomOffsetTop={customOffsetTop}
            />
            <TrendWrapper
                {...{ currentBreakpoint, scrollPos, windowSize }}
                blocks={[
                    <Trend04AdvertisersWelcome {...{ scrollPos, windowSize }} />,
                    <Trend04ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                ]}
                tickerBackgroundColor="neon-plum-20t"
                trendNumber="04"
                tickerCustomOffsetTop={customOffsetTop}
            />
        </div>
    );
};
