import {Fragment} from "react";

import {FadeInSlideUp, SectionIntro, StaggerFromBaseline, ThoughtBubble, TrendWrapper} from "../components";
import {
    GrowingCreatorSupport, GrowingCreatorSupportMobile, NoStoppingNow,
    NoStoppingNowMobile, PieChart, Section04Background, TempSoundwaveMapLeft,
    TempSoundwaveMapRight, TempUntappedContent, WaitingToBeReached,
    WaitingToBeReachedMobile,
} from "../images";
import {Breakpoints} from "../utils";

import "../styles/Section04.css";

const thoughtBubbleForegroundBgColor = "solid-tangerine";
const thoughtBubbleBgFill = "gradient-tangerine-neon-tangerine";

const Trend01TheListenerGlassIsHalfFull = ({scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="trend-01-the-listener-glass-is-half-full container">
        <div className="left-col">
            <div className="title gt-america-bold-80-76">The Listener Glass is Half Full</div>
            <div className="description gt-america-regular-32-40">An estimated 177M people in the US will have ever listened to a podcast in 2022</div>
            <div className="footnote gt-america-regular-6-10">Edison Research, The Infinite Dial 2022</div>
        </div>
        <div className="image-wrapper">
            <FadeInSlideUp {...{scrollPos, windowSize}} className="anim-wrapper">
                <img src={TempSoundwaveMapLeft} />
            </FadeInSlideUp>
            <FadeInSlideUp {...{scrollPos, windowSize}} className="anim-wrapper" waitSeconds={0.5}>
                <img src={TempSoundwaveMapRight} />
            </FadeInSlideUp>
        </div>
    </FadeInSlideUp>
);

const Trend01ThoughtBubble = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="thought-bubble-wrapper container">
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="No stopping now."
            description="Approximately half of the U.S. population has still never listened to a podcast—that’s millions of potential consumers that have yet to fall in love with podcasts like we have. And we have the content to do just that."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={NoStoppingNow}
            mobileImage={NoStoppingNowMobile}
        />
    </FadeInSlideUp>
);

const Trend02UntappedContent = ({scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="trend-02-untapped-content container">
        <div className="left-col">
            <div className="title gt-america-bold-80-76">Untapped Content</div>
            <div className="description gt-america-regular-32-40">Simplecast Creators when asked, <b>“Are you currently monetizing your podcast?”</b></div>
        </div>
        <div className="image-wrapper">
            <div className="yes-value gt-america-regular-145-175">
                <StaggerFromBaseline {...{scrollPos, windowSize}} text="27%" />
            </div>
            <div className="no-value gt-america-regular-145-175">
                <StaggerFromBaseline {...{scrollPos, windowSize}} text="73%" />
            </div>
            <img src={TempUntappedContent} />
        </div>
    </FadeInSlideUp>
);

const Trend02ThoughtBubble = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="thought-bubble-wrapper container">
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="Growing creator support."
            description="There are an abundance of podcasters who are on the cusp of being able to monetize—and with a focus on creator tech, new tools are making it possible. For advertisers, thats great news: this means more content on the market with listeners waiting and willing to hear from you."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={GrowingCreatorSupport}
            mobileImage={GrowingCreatorSupportMobile}
        />
    </FadeInSlideUp>
);

const Trend03MissedConnections = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="trend-03-missed-connections container">
        <div>
            <div className="title gt-america-bold-80-76">Missed Connections</div>
            <div className="description gt-america-regular-32-40">Biggest Audience Opportunities</div>
            <div className="footnote gt-america-regular-6-10">AdsWizz Platform Internal Data, 2021, Top Contextual Audiences by % of Available Inventory</div>
            <div className="missed-connections">
                {[
                    "Graphic Designers",
                    "Interested in Music",
                    "Interested in Live Events",
                    "Interested in Movies",
                    "Marital Status Engaged",
                    "In-Market for Insurance",
                    "Interested in Nature and Outdoor Activities",
                    "Education, College Grad",
                    "Interested in Politics",
                    "Small Business Owners",
                ].map((connection, index) => (
                    <Fragment key={index}>
                        {index < 3 ? (
                            <ThoughtBubble
                                {...{currentBreakpoint, scrollPos, windowSize}}
                                backgroundFill="gradient-tangerine-neon-tangerine"
                                customContents={(
                                    <div className="missed-connection" key={index}>
                                        <div className="number gt-america-bold-56-70">{index + 1}</div>
                                        <div className="connection-name gt-america-regular-18-20">{connection}</div>
                                    </div>
                                )}
                                customPadding="0"
                                fitContentWidth
                                outlineColor="neutral-black"
                                pushAmount={currentBreakpoint === Breakpoints.MOBILE ? 10 : 20}
                                pushDirection="up-left"
                            />
                        ) : (
                            <div className="missed-connection">
                                <div className="number gt-america-bold-56-70">{index + 1}</div>
                                <div className="connection-name gt-america-regular-18-20">{connection}</div>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    </FadeInSlideUp>
);

const Trend03ThoughtBubble = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="thought-bubble-wrapper container">
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="Waiting to be Reached."
            description="Advanced targeting and reporting capabilities give advertisers the ability to both hone in on their desired audience and zoom in on missed audience opportunities to better optimize for next time."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={WaitingToBeReached}
            mobileImage={WaitingToBeReachedMobile}
        />
    </FadeInSlideUp>
);

export const Section04 = ({currentBreakpoint, scrollPos, windowSize}) => {
    return (
        <div className="section-04">
            <SectionIntro
                {...{scrollPos, windowSize}}
                backgroundColor="solid-tangerine"
                backgroundImage={Section04Background}
                image3d={PieChart}
                leftColText={
                    "There’s new opportunity on the table. And it’s waiting " +
                    "for you to take it. "
                }
                rightColText={
                    "Podcasts have reached critical mass—and then some. The " +
                    "amount of untapped potential for creators, advertisers, " +
                    "and publishers is enormous, and we’re here to help you " +
                    "reach it. "
                }
                sectionTitle="Opportunity"
            />
            <div style={{marginTop: currentBreakpoint === Breakpoints.MOBILE ? -52 : -82}}>
                <TrendWrapper
                    {...{currentBreakpoint, scrollPos, windowSize}}
                    blocks={[
                        <Trend01TheListenerGlassIsHalfFull {...{scrollPos, windowSize}} />,
                        <Trend01ThoughtBubble {...{currentBreakpoint, scrollPos, windowSize}} />,
                    ]}
                    tickerBackgroundColor="solid-stitcher-blue-20t"
                    trendNumber="01"
                />
            </div>
            <TrendWrapper
                {...{currentBreakpoint, scrollPos, windowSize}}
                blocks={[
                    <Trend02UntappedContent {...{scrollPos, windowSize}} />,
                    <Trend02ThoughtBubble {...{currentBreakpoint, scrollPos, windowSize}} />,
                ]}
                tickerBackgroundColor="solid-stitcher-blue-20t"
                trendNumber="02"
            />
            <TrendWrapper
                {...{currentBreakpoint, scrollPos, windowSize}}
                blocks={[
                    <Trend03MissedConnections {...{currentBreakpoint, scrollPos, windowSize}} />,
                    <Trend03ThoughtBubble {...{currentBreakpoint, scrollPos, windowSize}} />,
                ]}
                tickerBackgroundColor="solid-stitcher-blue-20t"
                trendNumber="03"
            />
        </div>
    );
};
