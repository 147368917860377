import {useEffect, useRef, useState} from "react";

import {OutlineText} from "../components";
import {Breakpoints, isEven, range} from "../utils";

import "../styles/Ticker.css";

const scrollSpeed = 0.5;
const numTickerBlocks = 6;

export const Ticker = ({backgroundColor, currentBreakpoint, outlineColor = "neutral-black", scrollPos, text, windowSize, customOffsetTop = null}) => {
    const tickerRef = useRef(null);
    const [verticalScroll, setVerticalScroll] = useState(0);

    // animate vertical scrolling of ticker on user scroll
    useEffect(() => {
        const currentRefPos = customOffsetTop || tickerRef?.current?.offsetTop;
        const currentRefWidth = tickerRef?.current?.clientWidth;
        const currentRefHeight = tickerRef?.current?.clientHeight;

        if (currentRefPos && currentRefWidth && scrollPos && windowSize) {
            const screenTop = scrollPos;
            const screenBottom = screenTop + windowSize.height;
            const currentPercWithinScreen = (currentRefPos - (screenTop - currentRefHeight)) / windowSize.height;

            // if ticker is below screen, keep at start
            if (currentRefPos > screenBottom) {
                setVerticalScroll(0);
            }

            // if ticker is above screen, keep at end
            else if (currentRefPos < (screenTop - currentRefHeight)) {
                setVerticalScroll(currentRefWidth);
            }

            // if ticker is within screen, slide ticker
            else {
                setVerticalScroll(currentRefWidth * currentPercWithinScreen * scrollSpeed);
            }
        }
    }, [scrollPos, windowSize]);

    return (
        <div
            className="component ticker"
            ref={tickerRef}
            style={{transform: `translateX(-${verticalScroll}px)`}}
        >
            {range(numTickerBlocks).map(index => (
                <div
                    className={`ticker-block ${isEven(index) ? "default" : "outline"} gt-america-compressed-128-136`}
                    key={index}
                    style={isEven(index) ? {} : {backgroundColor}}
                >
                    {isEven(index) ? (
                        <>{text}</>
                    ) : (
                        <OutlineText
                            outlineColor={outlineColor}
                            outlineWidth={currentBreakpoint === Breakpoints.MOBILE ? 1 : 2}
                        >
                            {text}
                        </OutlineText>
                    )}

                </div>
            ))}
        </div>
    );
};
