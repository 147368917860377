const ArrowUpRight = ({fillColor = "white", height = 24}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.20324 18.1144L6.8667 16.7233L14.0268 9.54961L10.6309 9.60416V7.88574H16.9999V14.2684H15.2269L15.2679 10.8998L8.20324 18.1144Z" />
    </svg>
);

const BackToTopCircle = ({fillColor = "white", height = 178}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 179 178" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.7083 164.368C43.7778 163.718 42.1251 164.375 41.2111 165.898C39.9265 168.038 40.4296 170.647 43.6671 172.8L50.0621 177.053L58.8316 162.442L52.1768 158.017C48.9393 155.864 46.2736 156.625 44.8903 158.93C43.7292 160.864 44.0426 162.857 45.7083 164.368ZM46.0362 170.949C44.5774 169.979 44.3825 168.813 45.0001 167.784C45.5929 166.796 46.6542 166.379 48.173 167.389L50.2914 168.798L48.1546 172.358L46.0362 170.949ZM51.6463 161.09L53.9844 162.645L51.6747 166.493L49.2566 164.885C47.8377 163.942 47.5553 162.689 48.2346 161.557C48.9016 160.446 50.1074 160.067 51.6463 161.09Z" />
        <path d="M23.1642 121.108L21.5657 117.354L8.58086 130.322L10.3957 134.584L28.2326 133.01L26.6434 129.278L22.5326 129.786L20.0689 124L23.1642 121.108ZM19.5829 130.143L14.674 130.736C13.9071 130.832 13.2364 130.91 12.259 131.186L12.2402 131.141C13.086 130.557 13.5874 130.081 14.1662 129.543L17.8527 126.08L19.5829 130.143Z" />
        <path d="M19.4973 83.909C20.0622 79.723 18.1366 76.766 14.6853 75.425L13.2582 79.3608C15.397 80.0203 16.5086 81.4725 16.2551 83.3515C15.8955 86.0153 13.4589 87.2039 10.1121 86.7035C6.67038 86.1889 4.71262 84.3675 5.05928 81.7988C5.33212 79.7771 6.80452 78.7355 9.06782 78.8313L8.90462 74.6577C5.16453 74.5595 2.42499 76.916 1.84401 81.221C1.15068 86.3584 4.26366 90.0267 9.58055 90.8217C14.8974 91.6167 18.7751 89.2605 19.4973 83.909Z" />
        <path d="M37.2709 42.6769L40.2453 39.1575L28.7936 37.0375L27.5902 27.7672L24.6003 31.3049L26.0549 40.909L20.7117 35.9058L18.0936 39.0035L30.5318 50.6505L33.1499 47.5527L29.4886 44.1243L29.0967 41.0975L37.2709 42.6769Z" />
        <path d="M103.485 2.01899L89.5789 0.988128L89.3642 4.19695L94.2947 4.56246L93.3718 18.3556L97.3928 18.6537L98.3157 4.86054L103.27 5.22782L103.485 2.01899Z" />
        <path d="M128.976 31.2319C133.166 34.1594 137.692 33.1393 140.624 28.4903C143.555 23.8413 142.69 19.0553 138.499 16.1277C134.309 13.2002 129.783 14.2203 126.852 18.8693C123.933 23.4979 124.785 28.3043 128.976 31.2319ZM130.665 28.5521C128.482 27.0265 128.348 24.2719 130.255 21.247C132.175 18.2018 134.626 17.2819 136.81 18.8075C138.994 20.3331 139.12 23.0536 137.2 26.0988C135.28 29.144 132.849 30.0777 130.665 28.5521Z" />
        <path d="M178.303 92.2882L177.056 78.4001L173.855 78.7185L174.298 83.6426L160.541 85.0111L160.902 89.027L174.658 87.6585L175.102 92.6066L178.303 92.2882Z" />
        <path d="M154.66 123.943C152.657 128.647 154.473 133.136 159.443 135.481C164.414 137.826 168.806 136.266 170.809 131.562C172.812 126.859 170.996 122.37 166.026 120.024C161.077 117.69 156.663 119.24 154.66 123.943ZM157.525 125.295C158.569 122.844 161.165 122.319 164.399 123.845C167.655 125.381 168.987 127.76 167.944 130.211C166.9 132.662 164.335 133.175 161.079 131.639C157.824 130.103 156.481 127.746 157.525 125.295Z" />
        <path d="M137.826 171.448L144.121 167.262L135.351 152.652L131.974 154.898L135.161 160.207L132.323 162.094C129.125 164.22 128.234 167.347 129.975 170.248C131.643 173.026 134.748 173.495 137.826 171.448ZM134.52 164.03L136.618 162.635L139.237 166.997L137.138 168.393C135.619 169.403 134.278 169.172 133.451 167.793C132.623 166.414 133.021 165.027 134.52 164.03Z" />
    </svg>
);

const Checkmark = ({fillColor = "black", height = 16}) => (
    <svg fill="transparent" height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="6.5" stroke={fillColor} />
        <path d="M5 7.71429L7 10L11 6" stroke={fillColor} />
    </svg>
);

const Copy = ({fillColor = "white", height = 16}) => (
    <svg fill="transparent" height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.5" y="1.5" width="8" height="11" stroke={fillColor} />
        <path fillRule="evenodd" clipRule="evenodd" d="M4 4H3H2V5V15V16H3H10H11V15V14H10V15H3V5H4V4Z" fill={fillColor} />
    </svg>
);

const DownArrow = ({fillColor = "white", height = 24}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.955 6.27481L12.8837 6.31339L12.8933 16.4489L15.256 14.0091L16.4712 15.2242L11.9676 19.7278L7.45431 15.2145L8.70799 13.9608L11.061 16.3718L10.955 6.27481Z" />
    </svg>
);

const Download = ({fillColor = "white", height = 24}) => (
    <svg fill="transparent" height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4.5V14.5M16 12L12 15L8 12" stroke={fillColor} />
        <line x1="6" y1="19.5" x2="19" y2="19.5" stroke={fillColor} />
    </svg>
);

const Error = ({fillColor = "#FF998A", height = 16}) => (
    <svg height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="6.5" stroke={fillColor} />
        <rect x="7.5" y="4" width="1" height="6" fill={fillColor} />
        <rect x="7.5" y="11" width="1" height="1" fill={fillColor} />
    </svg>
);

const HamburgerMenu = ({fillColor = "white", height = 18}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="3" transform="matrix(-1 -8.74226e-08 -8.74229e-08 1 20.6792 7.96973)" />
        <rect width="20" height="3" transform="matrix(-1 -8.74226e-08 -8.74229e-08 1 20.6792 0.969727)" />
        <rect width="20" height="3" transform="matrix(-1 -8.74226e-08 -8.74229e-08 1 20.6787 14.9697)" />
    </svg>
);

const PlayButton = ({fillColor = "black", height = 30}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 26 30" xmlns="http://www.w3.org/2000/svg">
        <rect width="2" height="30" />
        <rect x="6" y="3" width="2" height="24" />
        <rect x="12" y="6" width="2" height="18" />
        <rect x="18" y="9" width="2" height="12" />
        <rect x="24" y="12" width="2" height="6" />
    </svg>
);

const PauseButton = ({fillColor = "black", height = 24}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg">
        <rect width="2" height="24" />
        <rect x="10" width="2" height="24" />
    </svg>
);

const RightArrow = ({fillColor = "black", height = 16}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.179688 9.1308V6.85524H11.593L8.19746 3.6908L10.0286 2.17969L15.8419 7.97524L10.0286 13.7886L8.17969 12.2241L11.593 9.1308H0.179688Z" />
    </svg>
);

const TheNewNewCircle = ({fillColor = "white", height = 69}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 69 69" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.3236 63.135L27.8281 65.6451L28.3905 64.1386L26.0842 63.2486L28.5015 56.7731L26.6207 56.0473L24.2034 62.5228L21.886 61.6286L21.3236 63.135Z" />
        <path d="M9.03631 51.632L10.24 53.2642L12.9598 51.1908L14.9041 53.8274L12.1843 55.9008L13.3879 57.533L20.1636 52.3676L18.96 50.7354L16.1447 52.8816L14.2004 50.245L17.0156 48.0988L15.812 46.4667L9.03631 51.632Z" />
        <path d="M11.8467 38.4568L9.73919 38.5937L9.51336 35.0008L8.02849 35.0973L8.25432 38.6902L6.33836 38.8147L6.09973 35.0182L4.60289 35.1154L4.96873 40.936L13.4708 40.3835L13.0997 34.4792L11.6028 34.5764L11.8467 38.4568Z" />
        <path d="M16.6763 22.4814L18.0165 21.1918L14.7298 17.6607C14.4436 17.3533 14.1897 17.0981 13.9526 16.8434L13.9699 16.8267C14.2603 16.9802 14.6357 17.1018 15.0444 17.2415L20.429 18.8703L21.9855 17.3727L16.1805 11.1363L14.8402 12.4259L18.1597 15.9921C18.4377 16.2907 18.6834 16.5372 18.9124 16.7831L18.8951 16.7997C18.6047 16.6462 18.1547 16.4798 17.7711 16.3494L12.4883 14.6891L10.8713 16.245L16.6763 22.4814Z" />
        <path d="M29.3529 12.3425L28.9842 10.2629L32.5252 9.61401L32.2655 8.14886L28.7245 8.79776L28.3893 6.90724L32.131 6.22156L31.8692 4.74459L26.1327 5.79581L27.6199 14.185L33.439 13.1187L33.1772 11.6417L29.3529 12.3425Z" />
        <path d="M40.6454 13.5571L42.6595 14.5733L46.2466 9.90526C46.3629 9.74893 46.4738 9.57642 46.5846 9.37693L46.6274 9.39855C46.5381 9.60885 46.4594 9.79759 46.4073 9.98635L44.9201 15.714L46.9449 16.7357L52.4927 9.99265L50.6714 9.07367L47.2699 13.5531C47.1113 13.7688 47.0058 13.9575 46.8951 14.157L46.863 14.1407C46.9523 13.9304 47.0256 13.7255 47.0878 13.4612L48.4323 7.94386L46.6218 7.03028L43.1238 11.4611C42.9545 11.6713 42.8383 11.8546 42.7276 12.0541L42.6954 12.0379C42.7847 11.8276 42.8688 11.6281 42.9417 11.3692L44.3934 5.90587L42.5721 4.98689L40.6454 13.5571Z" />
        <path d="M55.8364 28.1115L56.0725 29.9565L60.8548 29.324C61.2712 29.2689 61.6266 29.2098 61.9716 29.1641L61.9746 29.1879C61.679 29.336 61.3584 29.5721 61.0036 29.8248L56.4974 33.2774L56.7715 35.42L65.218 34.3028L64.9819 32.4578L60.152 33.0967C59.7475 33.1502 59.404 33.2077 59.0709 33.2517L59.0679 33.2279C59.3635 33.0799 59.7643 32.809 60.0969 32.5714L64.5677 29.2202L64.2829 26.9944L55.8364 28.1115Z" />
        <path d="M56.4494 44.3966L58.3449 45.328L56.7994 48.5794L58.1349 49.2356L59.6804 45.9842L61.4036 46.831L59.7706 50.2666L61.1168 50.9281L63.6205 45.6609L55.9737 41.9035L53.434 47.2466L54.7803 47.9081L56.4494 44.3966Z" />
        <path d="M48.9462 53.0621L46.9541 54.1208L48.56 59.8168C48.616 60.0044 48.6881 60.1971 48.7818 60.4055L48.7394 60.428C48.6245 60.2309 48.5207 60.0551 48.4011 59.9012L44.7182 55.309L42.7154 56.3733L44.8199 64.9024L46.6214 63.9451L45.0564 58.5127C44.9781 58.2554 44.8899 58.0576 44.7963 57.8492L44.8281 57.8323C44.9429 58.0295 45.0629 58.2103 45.2366 58.4169L48.8361 62.7681L50.6269 61.8164L49.1573 56.3334C49.0896 56.0704 49.012 55.867 48.9184 55.6586L48.9502 55.6417C49.065 55.8389 49.1744 56.0254 49.3374 56.2376L52.831 60.6451L54.6324 59.6878L48.9462 53.0621Z" />
    </svg>
);

const UpArrow = ({fillColor = "white", height = 32}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 26 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.46932 17.5287L0 13.2892L13.0399 0L26 13.2892L22.6503 17.5287L15.6718 9.8242V32H10.2883V9.8242L3.46932 17.5287Z" />
    </svg>
);

const X = ({fillColor = "white", height = 17}) => (
    <svg fill={fillColor} height={height} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="3" transform="matrix(-0.707107 0.707107 0.707107 0.707107 14.1406 0)" />
        <rect x="2.12109" width="20" height="3" transform="rotate(45 2.12109 0)" />
    </svg>
);

export const IconTypes = {
    ARROW_UP_RIGHT: "ARROW_UP_RIGHT",
    BACK_TO_TOP_CIRCLE: "BACK_TO_TOP_CIRCLE",
    CHECKMARK: "CHECKMARK",
    COPY: "COPY",
    DOWN_ARROW: "DOWN_ARROW",
    DOWNLOAD: "DOWNLOAD",
    ERROR: "ERROR",
    HAMBURGER_MENU: "HAMBURGER_MENU",
    PLAY_BUTTON: "PLAY_BUTTON",
    PAUSE_BUTTON: "PAUSE_BUTTON",
    RIGHT_ARROW: "RIGHT_ARROW",
    THE_NEW_NEW_CIRCLE: "THE_NEW_NEW_CIRCLE",
    UP_ARROW: "UP_ARROW",
    X: "X",
};

export const Icon = ({fillColor, height, type}) => {
    const TypeToIconMap = {
        [IconTypes.ARROW_UP_RIGHT]: <ArrowUpRight {...{fillColor, height}} />,
        [IconTypes.BACK_TO_TOP_CIRCLE]: <BackToTopCircle {...{fillColor, height}} />,
        [IconTypes.CHECKMARK]: <Checkmark {...{fillColor, height}} />,
        [IconTypes.COPY]: <Copy {...{fillColor, height}} />,
        [IconTypes.DOWN_ARROW]: <DownArrow {...{fillColor, height}} />,
        [IconTypes.DOWNLOAD]: <Download {...{fillColor, height}} />,
        [IconTypes.ERROR]: <Error {...{fillColor, height}} />,
        [IconTypes.HAMBURGER_MENU]: <HamburgerMenu {...{fillColor, height}} />,
        [IconTypes.PLAY_BUTTON]: <PlayButton {...{fillColor,height}} />,
        [IconTypes.PAUSE_BUTTON]: <PauseButton {...{fillColor, height}} />,
        [IconTypes.RIGHT_ARROW]: <RightArrow {...{fillColor, height}} />,
        [IconTypes.THE_NEW_NEW_CIRCLE]: <TheNewNewCircle {...{fillColor, height}} />,
        [IconTypes.UP_ARROW]: <UpArrow {...{fillColor, height}} />,
        [IconTypes.X]: <X {...{fillColor, height}} />,
    };

    return <div className="icon" style={{height: height}}>{TypeToIconMap[type]}</div>;
};
