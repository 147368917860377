import {Fragment, useEffect, useRef, useState} from "react";

import {KeithMorrison, NeilDeGrasseTyson} from "../audio";
import {AudioPlayer, FadeInSlideUp, Icon, IconTypes, SectionIntro, StaggerFromBaseline, ThoughtBubble, TrendWrapper} from "../components";
import {
    ACommunityForEveryone, ACommunityForEveryoneMobile, BeyondTheSoundbooth,
    BeyondTheSoundboothMobile, CoverArt1, CoverArt2, CoverArt3, CoverArt4,
    CoverArt5, CoverArt6, CoverArt7, CoverArt8, MarathonTimeAnyone, Microphones,
    IndiesLeadTheWay, IndiesLeadTheWayMobile, MarathonTimeAnyoneMobile,
    MTCCoverArt1, MTCCoverArt2, MTCCoverArt3, MTCCoverArt4, MTCCoverArt5,
    NIMCoverArt1, NIMCoverArt2, NIMCoverArt3, NIMCoverArt4, NIMCoverArt5,
    Section02Background, TempIndependentsKeepOnTop,
    TempIndependentsKeepOnTopMobile, TempWhatsGoodHollywood2,
    TempWhatsGoodHollywood3, TheExpandingUniverse, TheExpandingUniverseMobile,
} from "../images";
import {Breakpoints, wait} from "../utils";

import "../styles/Section02.css";

const thoughtBubbleForegroundBgColor = "solid-bright-peach";
const thoughtBubbleBgFill = "gradient-rose-peach-2";

const ChartRows = ({bgGradient, currentBreakpoint, data, scrollPos, topRowBgGradient, windowSize}) => {
    const Row = ({data, index}) => {
        let iconSize = 0;
        if (index === 0) {
            if (windowSize.width <= 465) {
                iconSize = 42;
            } else {
                iconSize = 48;
            }
        } else {
            if (windowSize.width <= 465) {
                iconSize = 32;
            } else {
                iconSize = 40;
            }
        }

        return (
            <div className="row" style={{
                background: index === 0
                    ? (topRowBgGradient || bgGradient)
                    : bgGradient
            }}>
                <div className="row-left-col">
                    <div className="stat">
                        <div className={`percentage ${index === 0 ? "gt-america-medium-72-50" : "gt-america-medium-56-40"}`}>
                            {data.percentage}%
                        </div>
                        <Icon height={iconSize} type={IconTypes.UP_ARROW} />
                    </div>
                    <div className="title gt-america-medium-21-24">
                        {data.title}
                    </div>
                </div>
                <div className="row-right-col">
                    <img src={data.coverArt} />
                </div>
            </div>
        );
    };

    return (
        <div className="chart-rows">
            {data.map((dataRow, index) => (
                <Fragment key={index}>
                    {index === 0 ? (
                        <ThoughtBubble
                            {...{currentBreakpoint, scrollPos, windowSize}}
                            backgroundFill="gradient-rose-peach-2"
                            customContents={(
                                <Row data={dataRow} index={index} key={index} />
                            )}
                            customPadding="0"
                            fitContentWidth
                            outlineColor="neutral-black"
                            pushAmount={windowSize.width <= 550 ? 10 : 20}
                        />
                    ) : (
                        <Row data={dataRow} index={index} key={index} />
                    )}
                </Fragment>
            ))}
        </div>
    );
};

const Trend01IndependentsKeepOnTop = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="trend-01-independents-keep-on-top container"
    >
        <div className="left-col">
            <div className="title gt-america-bold-80-76">Independents Keep on Top</div>
            <div className="description gt-america-regular-32-40">Of the top-downloaded podcasts on Simplecast…</div>
            <div className="footnote gt-america-regular-6-10">Simplecast Platform Data, Download Reports, October 2021</div>
        </div>
        <div className="right-col">
            <img src={
                currentBreakpoint === Breakpoints.MOBILE
                    ? TempIndependentsKeepOnTopMobile
                    : TempIndependentsKeepOnTop
            } />
        </div>
    </FadeInSlideUp>
);

const Trend01ThoughtBubble = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="Indies lead the way."
            description="Even as publishers and major media companies enter the space, indie podcasters remain a critical component in expanding the medium and continue to top the charts."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={IndiesLeadTheWay}
            mobileImage={IndiesLeadTheWayMobile}
        />
    </FadeInSlideUp>
);

const Trend02MurderingTheCharts = ({currentBreakpoint, scrollPos, windowSize}) => {
    const chartData = [
        {title: "True Crime", percentage: 101, coverArt: MTCCoverArt1},
        {title: "History", percentage: 73, coverArt: MTCCoverArt2},
        {title: "Fiction", percentage: 71, coverArt: MTCCoverArt3},
        {title: "Sports", percentage: 60, coverArt: MTCCoverArt4},
        {title: "Comedy", percentage: 54, coverArt: MTCCoverArt5},
    ];

    return (
        <FadeInSlideUp
            {...{scrollPos, windowSize}}
            className="trend-02-murdering-the-charts container"
        >
            <div className="left-col">
                <div className="title gt-america-bold-80-76">
                    Murdering the Charts
                </div>
                <div className="description gt-america-regular-32-40">
                    Fastest Growing Podcast Categories in 2021
                </div>
                <div className="footnote gt-america-regular-6-10">
                    Simplecast Internal Data, Number of Shows by Category, Jan 2021 vs Dec 2021
                </div>
            </div>
            <div className="right-col">
                <ChartRows
                    {...{currentBreakpoint, scrollPos, windowSize}}
                    bgGradient="linear-gradient(102.9deg, var(--color-transparent) 40.69%, var(--color-neon-peach) 102.43%), linear-gradient(0deg, #FF998A, #FF998A)"
                    data={chartData}
                />
            </div>
        </FadeInSlideUp>
    );
};

const Trend02ThoughtBubble1 = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="Marathon time, anyone?"
            description="Traditional fringe genres such as True Crime and History—which often focus on sequential storytelling and binge listening—are on the rise. Thanks to dynamic insertion, advertisers can stay relevant with timely ads no matter how far a listener goes back in the archives."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={MarathonTimeAnyone}
            mobileImage={MarathonTimeAnyoneMobile}
        />
    </FadeInSlideUp>
);

const Trend02NicheIsMainstream = ({currentBreakpoint, scrollPos, windowSize}) => {
    const chartData = [
        {title: "Wrestling", percentage: 109, coverArt: NIMCoverArt1},
        {title: "Stand-up Comedy", percentage: 105, coverArt: NIMCoverArt2},
        {title: "Earth Sciences", percentage: 88, coverArt: NIMCoverArt3},
        {title: "After Shows, TV & Film", percentage: 86, coverArt: NIMCoverArt4},
        {title: "Animation & Manga", percentage: 84, coverArt: NIMCoverArt5},
    ];

    return (
        <FadeInSlideUp
            {...{scrollPos, windowSize}}
            className="trend-02-niche-is-mainstream container"
        >
            <div className="left-col">
                <div className="title gt-america-bold-80-76">Niche is Mainstream</div>
                <div className="description gt-america-regular-32-40">Fastest Growing Podcast Subcategories in 2021</div>
                <div className="footnote gt-america-regular-6-10">Simplecast Internal Data, Number of Shows by Category, Jan 2021 vs Dec 2021</div>
            </div>
            <div className="right-col">
                <ChartRows
                    {...{currentBreakpoint, scrollPos, windowSize}}
                    bgGradient="var(--gradient-rose-peach-2)"
                    data={chartData}
                    topRowBgGradient="var(--gradient-rose-peach)"
                />
            </div>
        </FadeInSlideUp>
    );
};

const Trend02ThoughtBubble2 = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="A community for everyone."
            description="Podcasts allow creators to talk about any topic, attracting newer, younger, more diverse audiences. Advertisers can ensure no listener is left behind with voice-to-text transcription targeting, plugging into the right topic, no matter how niche."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={ACommunityForEveryone}
            mobileImage={ACommunityForEveryoneMobile}
        />
    </FadeInSlideUp>
);

const Trend03WhatsGoodHollywood1 = ({scrollPos, windowSize}) => {
    const sectionRef = useRef(null);
    const [startAnim2, setStartAnim2] = useState(false);
    const [startAnim3, setStartAnim3] = useState(false);
    const [startAnim4, setStartAnim4] = useState(false);
    const [startAnim5, setStartAnim5] = useState(false);
    const [startAnim6, setStartAnim6] = useState(false);
    const [startAnim7, setStartAnim7] = useState(false);
    const [startAnim8, setStartAnim8] = useState(false);

    useEffect(() => {
        const anim = async () => {
            if ((sectionRef.current.getBoundingClientRect().top - 200) < 0) {
                setStartAnim2(true);
                setStartAnim3(true);
                setStartAnim4(true);
                setStartAnim5(true);
                setStartAnim6(true);
                setStartAnim7(true);
                setStartAnim8(true);
            }
        };

        if (scrollPos && windowSize && sectionRef?.current) {
            anim();
        }
    }, [scrollPos, windowSize]);

    let ca2Pos = 0;
    let ca3Pos = 0;
    let ca4Pos = 0;
    let ca5Pos = 0;
    let ca6Pos = 0;
    let ca7Pos = 0;
    let ca8Pos = 0;

    let stepSize = 90;
    if (windowSize.width <= 930 && windowSize.width >= 540) {
        stepSize = 60;
    }

    if (startAnim8) {
        ca2Pos = stepSize * 1;
        ca3Pos = stepSize * 2;
        ca4Pos = stepSize * 3;
        ca5Pos = stepSize * 4;
        ca6Pos = stepSize * 5;
        ca7Pos = stepSize * 6;
        ca8Pos = stepSize * 7;
    } else if (startAnim7) {
        ca2Pos = stepSize * 1;
        ca3Pos = stepSize * 2;
        ca4Pos = stepSize * 3;
        ca5Pos = stepSize * 4;
        ca6Pos = stepSize * 5;
        ca7Pos = stepSize * 6;
        ca8Pos = stepSize * 6;
    } else if (startAnim6) {
        ca2Pos = stepSize * 1;
        ca3Pos = stepSize * 2;
        ca4Pos = stepSize * 3;
        ca5Pos = stepSize * 4;
        ca6Pos = stepSize * 5;
        ca7Pos = stepSize * 5;
        ca8Pos = stepSize * 5;
    } else if (startAnim5) {
        ca2Pos = stepSize * 1;
        ca3Pos = stepSize * 2;
        ca4Pos = stepSize * 3;
        ca5Pos = stepSize * 4;
        ca6Pos = stepSize * 4;
        ca7Pos = stepSize * 4;
        ca8Pos = stepSize * 4;
    } else if (startAnim4) {
        ca2Pos = stepSize * 1;
        ca3Pos = stepSize * 2;
        ca4Pos = stepSize * 3;
        ca5Pos = stepSize * 3;
        ca6Pos = stepSize * 3;
        ca7Pos = stepSize * 3;
        ca8Pos = stepSize * 3;
    } else if (startAnim3) {
        ca2Pos = stepSize * 1;
        ca3Pos = stepSize * 2;
        ca4Pos = stepSize * 2;
        ca5Pos = stepSize * 2;
        ca6Pos = stepSize * 2;
        ca7Pos = stepSize * 2;
        ca8Pos = stepSize * 2;
    } else if (startAnim2) {
        ca2Pos = stepSize * 1;
        ca3Pos = stepSize * 1;
        ca4Pos = stepSize * 1;
        ca5Pos = stepSize * 1;
        ca6Pos = stepSize * 1;
        ca7Pos = stepSize * 1;
        ca8Pos = stepSize * 1;
    }


    return (
        <FadeInSlideUp
            {...{scrollPos, windowSize}}
            className="trend-03-whats-good-hollywood-1 container"
        >
            <div className="title gt-america-bold-80-76" ref={sectionRef}>What’s Good, Hollywood?</div>
            <div className="cover-art-stack">
                <img src={CoverArt1} />
                <img src={CoverArt2} style={{left: ca2Pos * -1, top: ca2Pos}} />
                <img src={CoverArt3} style={{left: ca3Pos * -1, top: ca3Pos}} />
                <img src={CoverArt4} style={{left: ca4Pos * -1, top: ca4Pos}} />
                <img src={CoverArt5} style={{left: ca5Pos * -1, top: ca5Pos}} />
                <img src={CoverArt6} style={{left: ca6Pos * -1, top: ca6Pos}} />
                <img src={CoverArt7} style={{left: ca7Pos * -1, top: ca7Pos}} />
                <img src={CoverArt8} style={{left: ca8Pos * -1, top: ca8Pos}} />
            </div>
        </FadeInSlideUp>
    );
};

const Trend03WhatsGoodHollywood2 = ({scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="trend-03-whats-good-hollywood-2 container"
    >
        <div className="left-col">
            <div className="title gt-america-bold-80-76">What’s Good, Hollywood?</div>
            <div className="description gt-america-regular-32-40">
                <span><b>The Thing About Pam</b></span>
                <br />
                <span>Listener Growth</span>
            </div>
            <div className="footnote gt-america-regular-6-10">Simplecast Internal Data, The Thing About Pam, Downloads June 2021–March 2022</div>
        </div>
        <div className="right-col">
            <div>
                <img src={TempWhatsGoodHollywood2} />
            </div>
            <img src={TempWhatsGoodHollywood3} />
        </div>
    </FadeInSlideUp>
);

const Trend03ThoughtBubble = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="The expanding universe."
            description="If a TV show doesn’t have a podcast… did it even happen? Movie and TV fans are turning to podcasts (often as new listeners) to get more out of their favorite entertainment. And on the flipside, Hollywood is leaning on podcasts to build deeper connections with their audiences in ways they can’t through other media."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={TheExpandingUniverse}
            mobileImage={TheExpandingUniverseMobile}
        />
        <div className="audio-player-wrapper">
            <AudioPlayer
                {...{windowSize}}
                audioFile={KeithMorrison}
                backgroundColor="neon-peach"
                durationSeconds={24}
                title="Keith Morrison, Creator, Dateline"
            />
        </div>
    </FadeInSlideUp>
);

const BarChartRow = ({scrollPos, title, value, withAnimation = false, windowSize}) => {
    const rowRef = useRef(null);
    const [progressPerc, setProgressPerc] = useState(withAnimation ? 0 : value);
    const [opacity, setOpacity] = useState(withAnimation ? 0 : 1);

    useEffect(() => {
        if (withAnimation && scrollPos && windowSize && rowRef?.current) {
            if ((rowRef.current.getBoundingClientRect().top) < (windowSize.height * 0.75)) {
                setOpacity(1);
                setProgressPerc(value);
            }
        }
    }, [scrollPos, windowSize]);

    return (
        <div className="bar-chart-row" ref={rowRef} style={{opacity: opacity}}>
            <div className="title-row">
                <div className="title gt-america-bold-18-15">
                    {title}
                </div>
            </div>
            <div className="graph-and-value-row">
                <div className="graph">
                    <div className="progress" style={{width: progressPerc + "%"}}></div>
                </div>
                <div className="value gt-america-bold-64-70">
                    {withAnimation ? (
                        <StaggerFromBaseline
                            {...{scrollPos, windowSize}}
                            text={value + "%"}
                        />
                    ) : (
                        <span>{value + "%"}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

const Trend04ShowMeTheMoney = ({scrollPos, windowSize}) => {
    const fadeInGroupRef = useRef(null);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        if (scrollPos && windowSize && fadeInGroupRef?.current) {
            if ((fadeInGroupRef.current.getBoundingClientRect().top) < (windowSize.height * 0.75)) {
                setOpacity(1);
            }
        }
    }, [scrollPos, windowSize]);

    return (
        <FadeInSlideUp
            {...{scrollPos, windowSize}}
            className="trend-04-show-me-the-money container"
        >
            <div className="left-col">
                <div className="title gt-america-bold-80-76">Show Me the Money</div>
                <div className="description gt-america-regular-32-40">Monetization of Channels by Creators</div>
                <div className="footnote gt-america-regular-6-10">Simplecast Creator Survey, 210 podcasters surveyed in March 2022</div>
            </div>
            <div className="right-col">
                <BarChartRow
                    {...{scrollPos, windowSize}}
                    title="Social Media"
                    value={34}
                    withAnimation
                />
                <BarChartRow
                    {...{scrollPos, windowSize}}
                    title="Website"
                    value={30}
                    withAnimation
                />
                <div className="fade-in-group" ref={fadeInGroupRef} style={{opacity}}>
                    <BarChartRow {...{scrollPos, windowSize}} title="YouTube" value={23} />
                    <BarChartRow {...{scrollPos, windowSize}} title="Newsletter" value={10} />
                    <BarChartRow {...{scrollPos, windowSize}} title="Blog" value={10} />
                    <BarChartRow {...{scrollPos, windowSize}} title="Livestream" value={7} />
                </div>
            </div>
        </FadeInSlideUp>
    );
};

const Trend04ThoughtBubble = ({currentBreakpoint, scrollPos, windowSize}) => (
    <FadeInSlideUp
        {...{scrollPos, windowSize}}
        className="trend-04-thought-bubble -bubble-wrapper container"
    >
        <ThoughtBubble
            {...{currentBreakpoint, scrollPos, windowSize}}
            title="Beyond the soundbooth."
            description="Creators are thriving in every realm of the digital world. Streaming video and social media (e.g., Twitch, TikTok, etc.) have been incredibly popular extensions of the standard podcast format."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={BeyondTheSoundbooth}
            mobileImage={BeyondTheSoundboothMobile}
        />
        <div className="audio-player-wrapper">
            <AudioPlayer
                {...{windowSize}}
                audioFile={NeilDeGrasseTyson}
                backgroundColor="neon-peach"
                durationSeconds={22}
                title="Neil deGrasse Tyson, Creator, StarTalk"
            />
        </div>
    </FadeInSlideUp>
);

export const Section02 = ({currentBreakpoint, scrollPos, windowSize}) => {
    return (
        <div className="section-02">
            <SectionIntro
                {...{scrollPos, windowSize}}
                backgroundColor="neon-peach"
                backgroundImage={Section02Background}
                image3d={Microphones}
                leftColText={
                    "There’s a new creator on the scene. And they’re ready to " +
                    "take over."
                }
                rightColText={
                    "Today’s creators are redefining what it means to be a " +
                    "podcaster. By finding new audiences, new niches, and " +
                    "even new formats, they are breaking out of the podcast " +
                    "shell to create communities of fans everywhere."
                }
                sectionTitle="Creating"
            />
            <div style={{marginTop: currentBreakpoint === Breakpoints.MOBILE ? -52 : -82}}>
                <TrendWrapper
                    {...{currentBreakpoint, scrollPos, windowSize}}
                    blocks={[
                        <Trend01IndependentsKeepOnTop {...{currentBreakpoint, scrollPos, windowSize}} />,
                        <Trend01ThoughtBubble {...{currentBreakpoint, scrollPos, windowSize}} />,
                    ]}
                    tickerBackgroundColor="solid-rose-20t"
                    trendNumber="01"
                />
            </div>
            <TrendWrapper
                {...{currentBreakpoint, scrollPos, windowSize}}
                blocks={[
                    <Trend02MurderingTheCharts {...{currentBreakpoint, scrollPos, windowSize}} />,
                    <Trend02ThoughtBubble1 {...{currentBreakpoint, scrollPos, windowSize}} />,
                    <Trend02NicheIsMainstream {...{currentBreakpoint, scrollPos, windowSize}} />,
                    <Trend02ThoughtBubble2 {...{currentBreakpoint, scrollPos, windowSize}} />,
                ]}
                tickerBackgroundColor="solid-rose-20t"
                trendNumber="02"
            />
            <TrendWrapper
                {...{currentBreakpoint, scrollPos, windowSize}}
                blocks={[
                    <Trend03WhatsGoodHollywood1 {...{scrollPos, windowSize}} />,
                    <Trend03WhatsGoodHollywood2 {...{scrollPos, windowSize}} />,
                    <Trend03ThoughtBubble {...{currentBreakpoint, scrollPos, windowSize}} />,
                ]}
                tickerBackgroundColor="solid-rose-20t"
                trendNumber="03"
            />
            <TrendWrapper
                {...{currentBreakpoint, scrollPos, windowSize}}
                blocks={[
                    <Trend04ShowMeTheMoney {...{scrollPos, windowSize}} />,
                    <Trend04ThoughtBubble {...{currentBreakpoint, scrollPos, windowSize}} />,
                ]}
                tickerBackgroundColor="solid-rose-20t"
                trendNumber="04"
            />
        </div>
    );
};
