import { FadeInSlideUp, Icon, IconTypes, ThoughtBubble, Ticker } from "../components";
import { scrollToTop, Sections } from "../utils";

import "../styles/Outro.css";

export const Outro = ({ allSectionRefs, currentBreakpoint, scrollPos, windowSize }) => {
    const videoUrl = "https://player.vimeo.com/video/706702833?h=2530ab464a";
    const videoAspectRatio = { height: 1350, width: 2400 };

    // because this section is relatively positioned (so the sidebar will go under
    // it on scroll to "hide" it), the ticker's normal ref.offsetTop is only the
    // offset from the relative parent element, which is this section's div,
    // whereas we need it to be the document body. this gets the offset top
    // relative to the document body.
    const absoluteOffsetTop = Object.keys(allSectionRefs).map(sectionName => {
        if (sectionName !== Sections.OUTRO && allSectionRefs[sectionName]?.current) {
            return allSectionRefs[sectionName].current.clientHeight;
        } else {
            return 0;
        }
    }).reduce((total, current) => total + current);

    return (
        <div className="outro">
            <Ticker
                {...{ currentBreakpoint, scrollPos, windowSize }}
                backgroundColor="neutral-dark-gray-20t"
                outlineColor="neutral-white"
                text="Outro"
                customOffsetTop={absoluteOffsetTop}
            />
            <FadeInSlideUp {...{ scrollPos, windowSize }} className="container">
                <div className="here-for-it">
                    <div className="title gt-america-bold-80-76">Here For It</div>
                    <div className="description gt-america-regular-24-31">Podcasting is changing drastically—and we’re here for it. At SiriusXM Media, AdsWizz, and Simplecast, we are here every step of the way, supporting and driving growth for creators, publishers, and marketers alike. We believe that in order for podcasting to continue to grow, we must support what made it so special in the first place: an entrepreneurial, decentralized, specialized culture in an open ecosystem where everyone can thrive.</div>
                </div>
            </FadeInSlideUp>
            <FadeInSlideUp {...{ scrollPos, windowSize }} className="container">
                <div className="what-does-this-mean container">
                    <ThoughtBubble
                        {...{ currentBreakpoint, scrollPos, windowSize }}
                        backgroundFill="gradient-tangerine-gold"
                        customContents={(
                            <div className="box-contents">
                                <div className="title gt-america-bold-56-48">What does this all mean? </div>
                                <div className="description gt-america-regular-21-28">
                                    Simply put, podcasting has changed, and there’s a new way to advertise. As adtech improves and creativity flourishes, the opportunities are quite literally endless. Scaled, multi-faceted campaigns across the podcast ecosystem (host-reads + custom activation + announcer reads) are growing into the new normal and delivering success to advertisers everywhere. And no matter the rate of transformation, we make it simple to plan, buy, and measure—all in one place.
                                    <br />
                                    <br />
                                    Our love for podcasts? That’s one thing that will never change.
                                </div>
                            </div>
                        )}
                        foregroundBgColor="neutral-black"
                        outlineColor="neutral-white"
                    />
                </div>
            </FadeInSlideUp>
            <FadeInSlideUp {...{ scrollPos, windowSize }} className="container">
                <div className="video">
                    <div
                        className="vimeo-iframe"
                        style={{ paddingBottom: (videoAspectRatio.height / videoAspectRatio.width) * 100 + "%" }}
                    >
                        <iframe
                            allowFullScreen={true}
                            frameBorder={0}
                            id={`iframe-${videoUrl}`}
                            scrolling="no"
                            src={videoUrl}
                        ></iframe>
                    </div>
                </div>
                <div className="back-to-top" onClick={scrollToTop}>
                    <Icon type={IconTypes.BACK_TO_TOP_CIRCLE} />
                    <Icon type={IconTypes.UP_ARROW} />
                </div>
            </FadeInSlideUp>
        </div>
    );
};
