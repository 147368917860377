import {useEffect, useRef, useState} from "react";
import {Breakpoints} from "../utils";

import "../styles/ThoughtBubble.css";

export const ThoughtBubble = ({
    backgroundFill, currentBreakpoint, customContents, customPadding,
    description, fitContentWidth, foregroundBgColor, image, mobileImage,
    outlineColor = "neutral-black", pushAmount = 20, pushDirection = "up-right",
    scrollPos, title, windowSize,
}) => {
    const [viewed, setViewed] = useState(false);
    const thoughtBubbleRef = useRef(null);

    useEffect(() => {
        const triggerPosition = windowSize.width < 768
            ? windowSize.height
            : windowSize.height * 0.9;

        if (!viewed && !!scrollPos && windowSize && thoughtBubbleRef?.current?.getBoundingClientRect()) {
            if (thoughtBubbleRef.current.getBoundingClientRect().bottom < triggerPosition) {
                setViewed(true);
            }
        }
    }, [scrollPos, windowSize]);

    let componentWidth = "auto";
    if (!fitContentWidth) {
        if (currentBreakpoint === Breakpoints.MOBILE) {
            componentWidth = `calc(100% - ${pushAmount}px)`;
        } else if (windowSize.width <= 1200) {
            componentWidth = "var(--grid-width-10-col)";
        } else {
            componentWidth = "var(--grid-width-7-col)";
        }
    }

    let pushXSign = "";
    if (pushDirection !== "up-right") {
        pushXSign = "-";
    }
    let pushYSign = "";
    if (pushDirection === "up-right" || pushDirection === "up-left") {
        pushYSign = "-";
    }

    return (
        <div
            className="component thought-bubble"
            ref={thoughtBubbleRef}
            style={{width: componentWidth}}
        >
            <div className={`main-section ${customContents ? "" : "no-custom-contents"}`} style={{
                backgroundColor: `var(--color-${foregroundBgColor})`,
                border: `1px solid var(--color-${outlineColor})`,
                padding: customPadding || (currentBreakpoint === Breakpoints.MOBILE ? "24px 38px 24px 24px" : "40px 65px 24px 40px"),
                transform:
                    `translate(${pushXSign}${viewed ? pushAmount : 0}px, ` +
                    `${pushYSign}${viewed ? pushAmount : 0}px)`,
            }}>
                {customContents || (
                    <>
                        <div className="left-col">
                            {title && (
                                <div className="title gt-america-bold-56-48">{title}</div>
                            )}
                            <div className="description gt-america-regular-21-28">{description}</div>
                        </div>
                        <div className="right-col">
                            {image ? (
                                <img alt={title} src={currentBreakpoint === Breakpoints.MOBILE ? mobileImage : image} />
                            ) : (
                                <div className="missing-image"></div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className="background-gradient" style={{
                background: `var(--${backgroundFill})`,
                border: `1px solid var(--color-${outlineColor})`,
            }}></div>
        </div>
    );
};
