import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom/client';
import LinkedInTag from 'react-linkedin-insight';
import {googleAnalyticsInit} from '@impervaos/google-analytics-spa';

import {CookieBanner} from "./components";
import {
    Footer, Intro, Navbar, Outro, Section01,
    Section02, Section03, Section04, Sidebar,
} from "./file-sections";
import {Brands, Breakpoints, Sections, areCookiesAllowed, getCurrentBrand, useScroll, useWindow} from "./utils";

import "./styles/color-scheme.css";
import "./styles/grid.css";
import "./styles/typography.css";

const sectionColors = {
    [Sections.INTRO]: "neutral-black",
    [Sections.SECTION_01]: "neon-indigo",
    [Sections.SECTION_02]: "neon-peach",
    [Sections.SECTION_03]: "solid-sxm-blue",
    [Sections.SECTION_04]: "solid-tangerine",
    [Sections.OUTRO]: "neutral-black",
};

const getCurrentSection = (
    scrollPos, navbarHeight, introRef, section01Ref, section02Ref, section03Ref,
    section04Ref, outroRef,
) => {
    const getSectionStartPos = (ref) => ref.current.offsetTop - navbarHeight;

    if (
        !!scrollPos && introRef?.current && section01Ref?.current &&
        section02Ref?.current && section03Ref?.current &&
        section04Ref?.current && outroRef?.current
    ) {
        if (scrollPos > getSectionStartPos(outroRef)) {
            return {
                name: Sections.OUTRO,
                startPos: getSectionStartPos(outroRef),
                height: outroRef.current.clientHeight,
            };
        }
        else if (scrollPos > getSectionStartPos(section04Ref)) {
            return {
                name: Sections.SECTION_04,
                startPos: getSectionStartPos(section04Ref),
                height: section04Ref.current.clientHeight,
            };
        }
        else if (scrollPos > getSectionStartPos(section03Ref)) {
            return {
                name: Sections.SECTION_03,
                startPos: getSectionStartPos(section03Ref),
                height: section03Ref.current.clientHeight,
            };
        }
        else if (scrollPos > getSectionStartPos(section02Ref)) {
            return {
                name: Sections.SECTION_02,
                startPos: getSectionStartPos(section02Ref),
                height: section02Ref.current.clientHeight,
            };
        }
        else if (scrollPos > getSectionStartPos(section01Ref)) {
            return {
                name: Sections.SECTION_01,
                startPos: getSectionStartPos(section01Ref),
                height: section01Ref.current.clientHeight,
            };
        }
    }

    return {
        name: Sections.INTRO,
        startPos: 0,
        height: introRef?.current?.clientHeight || 0,
    };
};

const App = () => {
    const currentBrand = getCurrentBrand();

    const {scrollPosition} = useScroll();
    const {windowSize, breakpoint} = useWindow();
    const scrollPos = scrollPosition;
    const currentBreakpoint = breakpoint;

    const introPodcastsSectionHeight = currentBreakpoint === Breakpoints.MOBILE ? 3000 : 5300;
    const navbarHeight = currentBreakpoint === Breakpoints.MOBILE ? 80 : 64;

    const introRef = useRef(null);
    const section01Ref = useRef(null);
    const section02Ref = useRef(null);
    const section03Ref = useRef(null);
    const section04Ref = useRef(null);
    const outroRef = useRef(null);
    const allSectionRefs = {
        [Sections.INTRO]: introRef,
        [Sections.SECTION_01]: section01Ref,
        [Sections.SECTION_02]: section02Ref,
        [Sections.SECTION_03]: section03Ref,
        [Sections.SECTION_04]: section04Ref,
        [Sections.OUTRO]: outroRef,
    };
    const [backgroundColor, setBackgroundColor] = useState("neutral-black");
    const [showFooter, setShowFooter] = useState(false);
    const [isMenuExpanded, setMenuExpanded] = useState(false);
    const currentSection = getCurrentSection(
        scrollPos, navbarHeight, introRef, section01Ref, section02Ref,
        section03Ref, section04Ref, outroRef
    );

    // set up LinkedIn pixel for analytics
    useEffect(() => {
        if (currentBrand === Brands.SXM_MEDIA) {
            LinkedInTag.init("75490", 'dc', !areCookiesAllowed);
        }
    }, []);

    // set up Google Analytics
    useEffect(() => {
        if (areCookiesAllowed) {
            if (currentBrand === Brands.SXM_MEDIA) {
                googleAnalyticsInit("UA-59642422-1");
            }
            else if (currentBrand === Brands.ADSWIZZ) {
                googleAnalyticsInit("UA-160152018-1");
            }
        }
    }, [areCookiesAllowed]);

    // animate hiding/showing footer
    useEffect(() => {
        if (!!scrollPosition && windowSize && introRef?.current) {
            if (scrollPosition === 0) {
                setShowFooter(false);
            } else {
                const sectionStartPos = currentSection.startPos;
                const currentPosWithinSection = scrollPosition - sectionStartPos;
                const sectionHeight = introRef.current.clientHeight;

                setShowFooter(
                    currentSection.name === Sections.INTRO &&
                    currentPosWithinSection < (sectionHeight - introPodcastsSectionHeight - 84 - navbarHeight)
                );
            }
        }
    }, [scrollPos, windowSize]);

    return (
        <div style={{backgroundColor: `var(--color-${backgroundColor})`}}>
            <Navbar
                {...{allSectionRefs, currentBrand, currentBreakpoint, currentSection, navbarHeight, scrollPos, sectionColors, windowSize}}
                isMenuOpen={isMenuExpanded}
                setMenuOpen={setMenuExpanded}
            />
            {currentBreakpoint !== Breakpoints.MOBILE && (
                <Sidebar
                    {...{allSectionRefs, currentSection}}
                    hide={
                        currentSection.name === Sections.INTRO ||
                        currentSection.name === Sections.OUTRO
                    }
                />
            )}
            <div ref={introRef}>
                <Intro {...{currentBrand, currentBreakpoint, introPodcastsSectionHeight, navbarHeight, scrollPos, windowSize}} />
            </div>
            <div ref={section01Ref} style={{position: "relative", zIndex: 2}}>
                <Section01 {...{allSectionRefs, currentBreakpoint, scrollPos, windowSize}} />
            </div>
            <div ref={section02Ref}>
                <Section02 {...{currentBreakpoint, scrollPos, windowSize}} />
            </div>
            <div ref={section03Ref}>
                <Section03 {...{currentBreakpoint, scrollPos, windowSize}} />
            </div>
            <div ref={section04Ref}>
                <Section04 {...{currentBreakpoint, scrollPos, windowSize}} />
            </div>
            <div ref={outroRef}>
                <Outro {...{allSectionRefs, currentBreakpoint, scrollPos, windowSize}} />
            </div>
            <CookieBanner {...{windowSize}} />
            {!isMenuExpanded && currentBreakpoint !== Breakpoints.MOBILE && (
                <Footer allSectionRefs={allSectionRefs} isVisible={showFooter} />
            )}
        </div>
    );
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
