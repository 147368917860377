import {Fragment, useEffect, useRef, useState} from "react";
import {Ticker} from "./index";

import "../styles/TrendWrapper.css";

export const TrendWrapper = ({blocks, currentBreakpoint, scrollPos, tickerBackgroundColor, trendNumber, tickerCustomOffsetTop = null, windowSize}) => {
    const trendWrapperRef = useRef(null);
    const [offsetTop, setOffsetTop] = useState(0);

    useEffect(() => {
        if (tickerCustomOffsetTop && trendWrapperRef?.current) {
            setOffsetTop(tickerCustomOffsetTop + trendWrapperRef.current.offsetTop);
        }
    }, [scrollPos, windowSize]);

    return (
        <div className="component trend-wrapper" ref={trendWrapperRef}>
            <Ticker
                {...{currentBreakpoint, scrollPos, windowSize}}
                backgroundColor={tickerBackgroundColor}
                text={`Trend ${trendNumber}`}
                customOffsetTop={tickerCustomOffsetTop ? offsetTop : null}
            />
            {blocks.map((block, index) => (
                <Fragment key={index}>{block}</Fragment>
            ))}
        </div>
    );
};
