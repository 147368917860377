import {useEffect, useState} from "react";

import {Icon, IconTypes, Logo, OutlineText} from "../components";
import {HoverImageSection01, HoverImageSection02, HoverImageSection03, HoverImageSection04} from "../images";
import {Brands, Breakpoints, Sections, scrollToSection, scrollToTop, wait} from "../utils";
import PdfDocument from "../The2022PodcastTrendsReport.pdf";

import "../styles/Navbar.css";

const pageTitles = [
    {endingWord: "Listening", sectionName: Sections.SECTION_01, hoverImage: HoverImageSection01},
    {endingWord: "Creating", sectionName: Sections.SECTION_02, hoverImage: HoverImageSection02},
    {endingWord: "Advertising", sectionName: Sections.SECTION_03, hoverImage: HoverImageSection03},
    {endingWord: "Opportunity", sectionName: Sections.SECTION_04, hoverImage: HoverImageSection04},
];
const sectionTitles = {
    [Sections.INTRO]: "Intro",
    [Sections.SECTION_01]: "01",
    [Sections.SECTION_02]: "02",
    [Sections.SECTION_03]: "03",
    [Sections.SECTION_04]: "04",
    [Sections.OUTRO]: "Outro",
};
const progressBarColors = {
    [Sections.SECTION_01]: "neon-plum",
    [Sections.SECTION_02]: "solid-bright-peach",
    [Sections.SECTION_03]: "solid-stitcher-blue",
    [Sections.SECTION_04]: "neon-tangerine",
};

const ExpandedMenu = ({allSectionRefs, hideMenu, isMobileBreakpoint, navbarHeight}) => (
    <div
        className="expanded-menu gt-america-bold-64-68"
        style={{
            // 0.5px for the navbar bottom border
            marginTop: navbarHeight + 0.5,
            height: `calc(100vh - ${navbarHeight}px - 0.5px)`,
        }}>
        {pageTitles.map((title, index) => (
            <div className="row" key={index} onClick={() => {
                document.body.style.overflow = "unset";
                hideMenu();
                scrollToSection(allSectionRefs, title.sectionName);
            }}>
                <div className="section-number">
                    0{index + 1}
                </div>
                <div className="section-title">
                    The New <OutlineText textColor="neutral-black" outlineColor="neutral-white">New</OutlineText> in {title.endingWord}
                </div>
                {!isMobileBreakpoint && (
                    <img src={title.hoverImage} />
                )}
            </div>
        ))}
        <div className="download-button-wrapper">
            <a className="download-button" href={PdfDocument} target="_blank">
                <div className="title gt-america-bold-15-24">Download PDF</div>
                <Icon type={IconTypes.DOWN_ARROW} />
            </a>
        </div>
    </div>
);

const DownloadButton = ({currentSection, fullNameColor, isMobileBreakpoint, isTransparent, shortNameColor}) => {
    const [isHovered, setHovered] = useState(false);
    const [isActive, setActive] = useState(false);

    let buttonStyling = {};
    let outlineColor = "";

    if (isTransparent || currentSection.name === Sections.INTRO || currentSection.name === Sections.OUTRO) {
        buttonStyling.backgroundColor = "transparent";
        outlineColor = "white";
    } else {
        outlineColor = "black";
    }

    if (isHovered) {
        if (isTransparent || currentSection.name === Sections.INTRO || currentSection.name === Sections.OUTRO) {
            buttonStyling.backgroundColor = "var(--color-neutral-white)";
            outlineColor = "black";
        } else if (currentSection.name === Sections.SECTION_01) {
            buttonStyling.backgroundColor = "var(--color-neon-plum)";
        } else if (currentSection.name === Sections.SECTION_02) {
            buttonStyling.backgroundColor = "var(--color-solid-bright-peach)";
        } else if (currentSection.name === Sections.SECTION_03) {
            buttonStyling.backgroundColor = "var(--color-solid-stitcher-blue)";
        } else if (currentSection.name === Sections.SECTION_04) {
            buttonStyling.backgroundColor = "var(--color-neon-tangerine)";
        }
    }

    if (isActive) {
        buttonStyling.filter = "brightness(90%)";
    }

    return (
        <a
            className="download-button"
            href={PdfDocument}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onMouseDown={() => setActive(true)}
            onMouseUp={() => setActive(false)}
            style={{
                borderColor: `var(--color-neutral-${outlineColor})`,
                ...buttonStyling,
            }}
            target="_blank"
        >
            <Icon fillColor={outlineColor} height={20} type={IconTypes.DOWNLOAD} />
        </a>
    );
};

const ShareButton = ({currentSection, fullNameColor, isMobileBreakpoint, isTransparent, shortNameColor}) => {
    const [isHovered, setHovered] = useState(false);
    const [isActive, setActive] = useState(false);
    const [isUrlCopied, setUrlCopied] = useState(false);

    const onUrlCopied = async () => {
        setUrlCopied(true);
        navigator.clipboard.writeText(window.location.href);
        await wait(5);
        setUrlCopied(false);
    };

    let buttonText = "";
    if (isUrlCopied) {
        buttonText = "URL Copied";
    } else if (isHovered) {
        buttonText = "Copy URL";
    } else {
        buttonText = "Share";
    }

    let buttonStyling = {};
    let textStyling = {};
    let iconFill = "";

    if (isUrlCopied || isHovered) {
        if (isTransparent || currentSection.name === Sections.INTRO || currentSection.name === Sections.OUTRO) {
            buttonStyling.backgroundColor = "var(--color-neutral-white)";
            buttonStyling.borderColor = "var(--color-neutral-white)";
            textStyling.color = "var(--color-neutral-black)";
            iconFill = "black";
        } else if (currentSection.name === Sections.SECTION_01) {
            buttonStyling.backgroundColor = "var(--color-neon-plum)";
        } else if (currentSection.name === Sections.SECTION_02) {
            buttonStyling.backgroundColor = "var(--color-solid-bright-peach)";
        } else if (currentSection.name === Sections.SECTION_03) {
            buttonStyling.backgroundColor = "var(--color-solid-stitcher-blue)";
        } else if (currentSection.name === Sections.SECTION_04) {
            buttonStyling.backgroundColor = "var(--color-neon-tangerine)";
        }

        if (isActive) {
            buttonStyling.filter = "brightness(90%)";
        }
    }

    return (
        <div
            className="share-button"
            onClick={onUrlCopied}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onMouseDown={() => setActive(true)}
            onMouseUp={() => setActive(false)}
            style={{
                ...buttonStyling,
                borderColor: fullNameColor,
            }}
        >
            {!isMobileBreakpoint && (
                <div className="title gt-america-bold-15-24" style={{color: fullNameColor, ...textStyling}}>
                    {buttonText}
                </div>
            )}
            <Icon
                fillColor={iconFill || shortNameColor}
                type={isUrlCopied ? IconTypes.CHECKMARK : IconTypes.COPY}
            />
        </div>
    );
};

export const Navbar = ({allSectionRefs, currentBrand, currentBreakpoint, currentSection, isMenuOpen, navbarHeight, scrollPos, sectionColors, setMenuOpen, windowSize}) => {
    const [isTransparent, setTransparent] = useState(false);
    const [sectionProgress, setSectionProgress] = useState(0);

    const isMobileBreakpoint = currentBreakpoint === Breakpoints.MOBILE;

    useEffect(() => {
        if (!!scrollPos && windowSize) {
            const windowHeight = windowSize.height;
            const jumbotronHeight = windowHeight + (isMobileBreakpoint ? 0 : 280) + navbarHeight;
            const sectionStartPos = currentSection.startPos;
            const sectionHeight = currentSection.height;
            const currentPosWithinSection = scrollPos - sectionStartPos;

            // make navbar transparent if within section's jumbotron and expanded menu is not open and current section is not intro nor outro
            if (isMenuOpen) {
                setTransparent(false);
            // special case: navbar should be transparent for the last section of the intro
            } else if (currentSection.name === Sections.INTRO && currentPosWithinSection > (sectionHeight - (currentBreakpoint === Breakpoints.MOBILE ? 3000 : 5300) - 84 - navbarHeight)) {
                setTransparent(true);
            } else if (isIntroOrOutro) {
                setTransparent(false);
            } else {
                setTransparent(currentPosWithinSection < jumbotronHeight);
            }

            // amount the user has progressed through the current section (decimal value between 0 and 1, inclusive)
            setSectionProgress(currentPosWithinSection / sectionHeight);
        }
    }, [scrollPos, windowSize, isMenuOpen]);

    let bgColor = null;
    if (isMenuOpen) {
        bgColor = "var(--color-neutral-black)";
    } else if (isTransparent) {
        bgColor = "transparent";
    } else {
        bgColor = `var(--color-${sectionColors[currentSection.name]})`;
    }

    let currentPageTitle = "";
    if (isMenuOpen) {
        currentPageTitle = "Menu";
    } else if (currentSection.name !== Sections.INTRO) {
        currentPageTitle = sectionTitles[currentSection.name];
    }

    const isIntroOrOutro = currentSection.name === Sections.INTRO || currentSection.name === Sections.OUTRO;
    const shortNameColor = (isTransparent || isIntroOrOutro || isMenuOpen) ? "white" : "black";
    const fullNameColor = `var(--color-neutral-${shortNameColor})`;

    let logoHeight = 0;
    if (currentBrand === Brands.SXM_MEDIA) {
        logoHeight = isMobileBreakpoint ? 25 : 32;
    } else if (currentBrand === Brands.ADSWIZZ) {
        logoHeight = isMobileBreakpoint ? 15 : 20;
    }

    const logoStyling = {};
    if (currentBrand === Brands.ADSWIZZ) {
        logoStyling.padding = "0 7.5px";
    }

    return (
        <>
            <div className="navbar" style={{backgroundColor: bgColor, height: navbarHeight, borderBottomColor: fullNameColor}}>
                <div className="logo" onClick={() => {
                    document.body.style.overflow = "unset";
                    setMenuOpen(false);
                    scrollToTop();
                }} style={logoStyling}>
                    <Logo brand={currentBrand} fillColor={shortNameColor} height={logoHeight} />
                </div>
                <div className="center" style={{borderLeftColor: fullNameColor, borderRightColor: fullNameColor}}>
                    <div className="current-page gt-america-bold-24-24" style={{color: fullNameColor}}>
                        <OutlineText outlineColor={`neutral-${shortNameColor}`} outlineWidth={0.75}>
                            {currentPageTitle}
                        </OutlineText>
                        {(!isMenuOpen && currentSection.name !== Sections.INTRO && currentSection.name !== Sections.OUTRO) && (
                            <span> / 04</span>
                        )}
                    </div>
                    <div className="buttons">
                        <DownloadButton {...{currentSection, fullNameColor, isMobileBreakpoint, isTransparent, shortNameColor}} />
                        <ShareButton {...{currentSection, fullNameColor, isMobileBreakpoint, isTransparent, shortNameColor}} />
                    </div>
                </div>
                <div className="menu-icon" onClick={() => {
                    setMenuOpen(!isMenuOpen);
                    document.body.style.overflow = isMenuOpen ? "unset" : "hidden";
                }}>
                    <Icon fillColor={shortNameColor} height={56} type={IconTypes.THE_NEW_NEW_CIRCLE} />
                    <Icon fillColor={shortNameColor} height={14} type={isMenuOpen ? IconTypes.X : IconTypes.HAMBURGER_MENU} />
                </div>
                <div className="progress-bar-wrapper" style={{
                    backgroundColor: bgColor,
                    opacity: isTransparent || isIntroOrOutro ? 0 : 1
                }}>
                    <div className="progress-bar-border" style={{borderBottomColor: fullNameColor}}>
                        <div className="progress-bar" style={{
                            backgroundColor: `var(--color-${progressBarColors[currentSection.name]})`,
                            borderRightColor: fullNameColor,
                            width: `${sectionProgress * 100}%`,
                        }}></div>
                    </div>
                </div>
            </div>
            {isMenuOpen && (
                <ExpandedMenu
                    {...{allSectionRefs, isMobileBreakpoint, navbarHeight}}
                    hideMenu={() => setMenuOpen(false)}
                />
            )}
        </>
    );
};
