import {useEffect, useRef, useState} from "react";

import {wait} from "../utils";

import "../styles/StaggerFromBaseline.css";

export const StaggerFromBaseline = ({scrollPos, text, windowSize}) => {
    const initialAnimState = {transform: "translateY(100%)"};
    const finalAnimState = {transform: "translateY(0)"};

    const elementRef = useRef(null);
    const [charStyling0, setCharStyling0] = useState(initialAnimState);
    const [charStyling1, setCharStyling1] = useState(initialAnimState);
    const [charStyling2, setCharStyling2] = useState(initialAnimState);

    useEffect(() => {
        const animate = async () => {
            if (elementRef.current.getBoundingClientRect().top < (windowSize.height * 0.85)) {
                setCharStyling0(finalAnimState);
                await wait(0.1);
                setCharStyling1(finalAnimState);
                await wait(0.1);
                setCharStyling2(finalAnimState);
            }
        };

        if (elementRef?.current) {
            animate();
        }
    }, [scrollPos]);

    return (
        <div className="component stagger-from-baseline" ref={elementRef}>
            <span style={charStyling0}>{text[0]}</span>
            <span style={charStyling1}>{text[1]}</span>
            <span style={charStyling2}>{text[2]}</span>
        </div>
    );
};
