import {FadeInSlideUp, OutlineText} from "./index";

import "../styles/SectionIntro.css";

export const SectionIntro = ({
    backgroundColor, backgroundImage, image3d, leftColText,
    rightColText, scrollPos, sectionTitle, tickerBackgroundColor, windowSize
}) => {
    return (
        <div className="component section-intro">
            <div className="section-title" style={{backgroundImage: `url(${backgroundImage})`}}>
                <FadeInSlideUp
                    {...{scrollPos, windowSize}}
                    className="container"
                >
                    <div className="title gt-america-bold-80-76">
                        The <OutlineText outlineColor="neutral-white">New</OutlineText> New{' '}
                        <br />
                        in {sectionTitle}
                    </div>
                </FadeInSlideUp>
                <div className="transparent-to-color-fade" style={{
                    background: `linear-gradient(180deg, var(--color-transparent) 0%, var(--color-${backgroundColor}) 100%)`
                }}></div>
            </div>
            <FadeInSlideUp
                {...{scrollPos, windowSize}}
                className="container"
            >
                <div className="intro-text">
                    <div className="left-col gt-america-medium-26-30">{leftColText}</div>
                    <div className="right-col gt-america-regular-24-33">{rightColText}</div>
                </div>
                <img className="earbuds" src={image3d} />
            </FadeInSlideUp>
        </div>
    );
};
