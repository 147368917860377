import {Icon, IconTypes} from "../components";
import {scrollToSection, Sections} from "../utils";

import "../styles/Footer.css";

const footerLinks = [
    {title: "The New New in Listening", sectionName: Sections.SECTION_01},
    {title: "The New New in Creating", sectionName: Sections.SECTION_02},
    {title: "The New New in Advertising", sectionName: Sections.SECTION_03},
    {title: "The New New in Opportunity", sectionName: Sections.SECTION_04},
];

const footerHeight = 57;

export const Footer = ({allSectionRefs, isVisible}) => {
    return (
        <div className="footer" style={{transform: `translateY(${isVisible ? 0 : footerHeight}px)`}}>
            {footerLinks.map((footerLink, index) => (
                <div
                    className="footer-link gt-america-bold-14-17"
                    key={index}
                    onClick={() => scrollToSection(allSectionRefs, footerLink.sectionName)}
                >
                    <span>{footerLink.title}</span>
                    <Icon type={IconTypes.RIGHT_ARROW} />
                </div>
            ))}
        </div>
    );
};
