export const OutlineText = ({children, textColor = "transparent", outlineColor, outlineWidth = 1}) => {
    const color = textColor === "transparent"
        ? "transparent"
        : `var(--color-${textColor})`;

    return (
        <span className="component outline-text" style={{
            color,
            WebkitTextStroke: `${outlineWidth}px var(--color-${outlineColor})`,
        }}>{children}</span>
    );
};
