import { Fragment } from "react";

import { AaronGomeztrejo, MeganStewart, NatureValley } from "../audio";
import { AudioPlayer, FadeInSlideUp, SectionIntro, StaggerFromBaseline, ThoughtBubble, TrendWrapper } from "../components";
import {
    AYearlyShift, AYearlyShiftMobile, ContextIsEverything,
    ContextIsEverythingMobile, From0To60, From0To60Mobile, Megaphone,
    MoreThanOneRightStrategy, MoreThanOneRightStrategyMobile,
    Section03Background, TempSmoothingOutSpends, TempWhosDoingItRight,
    TempWhosDoingItRightMobile,
} from "../images";
import { Breakpoints } from "../utils";

import "../styles/Section03.css";

const thoughtBubbleForegroundBgColor = "solid-sxm-blue";
const thoughtBubbleBgFill = "gradient-indigo-plum";

const Trend01WhosDoingItRight = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="trend-01-whos-doing-it-right container"
    >
        <div className="left-col">
            <div className="title gt-america-bold-80-76">Who’s Doing It Right?</div>
            <div className="description gt-america-regular-32-40">2021 Biggest Spenders</div>
            <div className="footnote gt-america-regular-6-10">SiriusXM Media 2021 Revenue</div>
        </div>
        <img src={currentBreakpoint === Breakpoints.MOBILE ? TempWhosDoingItRightMobile : TempWhosDoingItRight} alt="" />
    </FadeInSlideUp>
);

const Trend01ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="From 0 to 60."
            description="New advertiser categories are making their way onto the charts, with the automotive industry winning the award for biggest up-and-comer. And, it’s paying off. After a recent podcast campaign, luxury car brand Volvo drove a 66% unaided recall with listeners."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={From0To60}
            mobileImage={From0To60Mobile}
        />
        <div className="audio-player-wrapper">
            <AudioPlayer
                {...{ windowSize }}
                audioFile={MeganStewart}
                backgroundColor="neon-plum"
                durationSeconds={33}
                title="Megan Stewart, Account Director, Ad Results Media"
            />
        </div>
    </FadeInSlideUp>
);

const Trend02SmoothingOutSpends = ({ scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="trend-02-smoothing-out-spends container"
    >
        <div className="title gt-america-bold-80-76">Smoothing Out Spends</div>
        <img src={TempSmoothingOutSpends} alt="" />
    </FadeInSlideUp>
);

const Trend02ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="A yearly shift."
            description="While 2020 followed the traditional quarterly spikes seen within most media spending, 2021 showed a potential shift in approach: an annual strategy with always-on spending throughout the year."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={AYearlyShift}
            mobileImage={AYearlyShiftMobile}
        />
        <div className="audio-player-wrapper">
            <AudioPlayer
                {...{ windowSize }}
                audioFile={AaronGomeztrejo}
                backgroundColor="neon-plum"
                durationSeconds={27}
                title="Aaron Gomeztrejo, Group Director, Programmatic, Wpromote"
            />
        </div>
    </FadeInSlideUp>
);

const Trend03YourFavoriteAudiences = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="trend-03-your-favorite-audiences container"
    >
        <div>
            <div className="title gt-america-bold-80-76">Your Favorite Audiences</div>
            <div className="description gt-america-regular-32-40">Most Targeted Contextual Audiences</div>
            <div className="footnote gt-america-regular-6-10">AdsWizz Internal Data, 2021, Top Contextual Audiences via AI Transcription Targeting</div>
            <div className="audiences">
                {[
                    "Doctors & Research",
                    "Businesses (of All Sizes)",
                    "New Parents",
                    "Interested in Education",
                    "IT Decision Makers",
                    "In-Market for Home Security",
                    "Small Business Owners",
                    "In-Market for Financial Planning",
                    "IT & Engineering Professionals",
                    "Interested in Politics",
                ].map((audience, index) => (
                    <Fragment key={index}>
                        {index < 3 ? (
                            <ThoughtBubble
                                {...{ currentBreakpoint, scrollPos, windowSize }}
                                backgroundFill="gradient-indigo-plum"
                                customContents={(
                                    <div className="audience" key={index}>
                                        <div className="number gt-america-bold-56-70">{index + 1}</div>
                                        <div className="audience-name gt-america-regular-18-20">{audience}</div>
                                    </div>
                                )}
                                customPadding="0"
                                fitContentWidth
                                outlineColor="neutral-black"
                                pushAmount={currentBreakpoint === Breakpoints.MOBILE ? 10 : 20}
                                pushDirection="up-left"
                            />
                        ) : (
                            <div className="audience">
                                <div className="number gt-america-bold-56-70">{index + 1}</div>
                                <div className="audience-name gt-america-regular-18-20">{audience}</div>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    </FadeInSlideUp>
);

const Trend03ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="Context is everything"
            description="Podcast targeting has come a loooong way (pause for pat on back). Using speech-to-text technology, we map 30,000+ keywords to multiple segments to create targeting based on overall interests, rather than just keywords."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={ContextIsEverything}
            mobileImage={ContextIsEverythingMobile}
        />
    </FadeInSlideUp>
);

const CreativityBlock = ({ alternateSides = false, currentBreakpoint, footnote, scrollPos, text, stat, windowSize }) => {
    return (
        <div className={`creativity-block ${alternateSides ? "alternate" : ""}`}>
            <ThoughtBubble
                {...{ currentBreakpoint, scrollPos, windowSize }}
                backgroundFill="gradient-indigo-plum"
                customContents={(
                    <div className="pop-out-block">
                        <div className="content gt-america-bold-18-22">
                            {text}
                        </div>
                    </div>
                )}
                customPadding="0"
                fitContentWidth
                outlineColor="neutral-black"
                pushAmount={currentBreakpoint === Breakpoints.MOBILE ? 10 : 20}
                pushDirection={alternateSides ? "up-right" : "up-left"}
            />
            <div className="info">
                <div className="value gt-america-regular-168-168">
                    <StaggerFromBaseline
                        {...{ scrollPos, windowSize }}
                        text={stat}
                    />
                </div>
                <div className="footnote gt-america-regular-6-10">
                    {footnote}
                </div>
            </div>
        </div>
    )
};

const Trend04CreativityAtScale = ({ currentBreakpoint, scrollPos, windowSize }) => {
    return (
        <FadeInSlideUp
            {...{ scrollPos, windowSize }}
            className="trend-04-creativity-at-scale container"
        >
            <div className="left-col">
                <div className="title gt-america-bold-80-76">Creativity At Scale</div>
                {windowSize.width <= 1368 && (
                    <CreativityBlock
                        {...{ currentBreakpoint, scrollPos, windowSize }}
                        alternateSides
                        footnote="SiriusXM Media Podcast Revenue by Campaign Type, 2021 vs 2020"
                        stat="16%"
                        text="INCREASE IN REVENUE FROM BRAND AWARENESS PODCAST CAMPAIGNS YoY"
                    />
                )}
                <CreativityBlock
                    {...{ currentBreakpoint, scrollPos, windowSize }}
                    footnote="SiriusXM Media Podcast Revenue by Ad Type, 2021 vs 2020"
                    stat="86%"
                    text="INCREASE IN REVENUE FROM ANNOUNCER-READ ADS YoY"
                />
            </div>
            {windowSize.width > 1368 && (
                <div className="right-col">
                    <CreativityBlock
                        {...{ currentBreakpoint, scrollPos, windowSize }}
                        alternateSides
                        footnote="SiriusXM Media Podcast Revenue by Campaign Type, 2021 vs 2020"
                        stat="16%"
                        text="INCREASE IN REVENUE FROM BRAND AWARENESS PODCAST CAMPAIGNS YoY"
                    />
                </div>
            )}
        </FadeInSlideUp>
    );
};

const Trend04ThoughtBubble = ({ currentBreakpoint, scrollPos, windowSize }) => (
    <FadeInSlideUp
        {...{ scrollPos, windowSize }}
        className="thought-bubble-wrapper container"
    >
        <ThoughtBubble
            {...{ currentBreakpoint, scrollPos, windowSize }}
            title="More than one right strategy."
            description="When you imagine a podcast ad, the traditional direct-response campaign might be the first thing that comes to mind. However, brands are quickly including podcasts in their overall brand awareness campaigns and as a mainstay in their media plans. (FYI, both are incredibly effective)."
            foregroundBgColor={thoughtBubbleForegroundBgColor}
            backgroundFill={thoughtBubbleBgFill}
            image={MoreThanOneRightStrategy}
            mobileImage={MoreThanOneRightStrategyMobile}
        />
        <div className="audio-player-wrapper">
            <AudioPlayer
                {...{ windowSize }}
                audioFile={NatureValley}
                backgroundColor="neon-plum"
                durationSeconds={199}
                title="Nature Valley Vignette, Produced by Studio Resonate"
            />
        </div>
    </FadeInSlideUp>
);

export const Section03 = ({ currentBreakpoint, scrollPos, windowSize }) => {
    return (
        <div className="section-03">
            <SectionIntro
                {...{ scrollPos, windowSize }}
                backgroundColor="solid-sxm-blue"
                backgroundImage={Section03Background}
                image3d={Megaphone}
                leftColText={
                    "There’s a new advertiser on the books. And they’re " +
                    "connecting to listeners everywhere."
                }
                rightColText={
                    "The reach and appeal of podcasts is broader than ever. " +
                    "Advertisers are taking note—and spending accordingly. " +
                    "If you’re not on this next list, you’re missing out on " +
                    "key audiences. "
                }
                sectionTitle="Advertising"
            />
            <div style={{ marginTop: currentBreakpoint === Breakpoints.MOBILE ? -52 : -82 }}>
                <TrendWrapper
                    {...{ currentBreakpoint, scrollPos, windowSize }}
                    blocks={[
                        <Trend01WhosDoingItRight {...{ currentBreakpoint, scrollPos, windowSize }} />,
                        <Trend01ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                    ]}
                    tickerBackgroundColor="solid-stitcher-blue-20t"
                    trendNumber="01"
                />
            </div>
            <TrendWrapper
                {...{ currentBreakpoint, scrollPos, windowSize }}
                blocks={[
                    <Trend02SmoothingOutSpends {...{ scrollPos, windowSize }} />,
                    <Trend02ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                ]}
                tickerBackgroundColor="solid-stitcher-blue-20t"
                trendNumber="02"
            />
            <TrendWrapper
                {...{ currentBreakpoint, scrollPos, windowSize }}
                blocks={[
                    <Trend03YourFavoriteAudiences {...{ currentBreakpoint, scrollPos, windowSize }} />,
                    <Trend03ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                ]}
                tickerBackgroundColor="solid-stitcher-blue-20t"
                trendNumber="03"
            />
            <TrendWrapper
                {...{ currentBreakpoint, scrollPos, windowSize }}
                blocks={[
                    <Trend04CreativityAtScale {...{ currentBreakpoint, scrollPos, windowSize }} />,
                    <Trend04ThoughtBubble {...{ currentBreakpoint, scrollPos, windowSize }} />,
                ]}
                tickerBackgroundColor="solid-stitcher-blue-20t"
                trendNumber="04"
            />
        </div>
    );
};
