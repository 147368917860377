import {useState} from "react";

import {areCookiesAllowed} from "../utils";

import "../styles/CookieBanner.css";

export const CookieBanner = ({windowSize}) => {
    const [isVisible, setVisible] = useState(!areCookiesAllowed);

    const bannerHeight = windowSize.width <= 530 ? 118 : 88;

    const handleClick = () => {
        localStorage.setItem("useCookies", true);
        setVisible(false);
    };

    return (
        <div className="component cookie-banner" style={{transform: `translateY(${isVisible ? 0 : bannerHeight}px)`}}>
            <div className="container">
                <div className="left-col gt-america-regular-16-20">
                    <span>This site uses cookies to improve your experience.</span>
                    <br />
                    <span>By using this site, you consent to cookies.</span>
                </div>
                <div className="right-col">
                    <div className="agree-button gt-america-bold-15-24" onClick={handleClick}>
                        I Agree
                    </div>
                </div>
            </div>
        </div>
    );
};
