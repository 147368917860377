import {useEffect, useState} from "react";

export const Brands = {
    ADSWIZZ: "ADSWIZZ",
    EDISON: "EDISON",
    NBC_UNIVERSAL: "NBC_UNIVERSAL",
    PANDORA: "PANDORA",
    PODSURVEY: "PODSURVEY",
    SIMPLECAST: "SIMPLECAST",
    SIGNAL_HILL: "SIGNAL_HILL",
    STITCHER: "STITCHER",
    STUDIO_RESONATE: "STUDIO_RESONATE",
    SXM_MEDIA: "SXM_MEDIA",
};

export const Sections = {
    INTRO: "INTRO",
    SECTION_01: "SECTION_01",
    SECTION_02: "SECTION_02",
    SECTION_03: "SECTION_03",
    SECTION_04: "SECTION_04",
    OUTRO: "OUTRO",
};

export const Breakpoints = {
    LARGE_DESKTOP: "LARGE_DESKTOP",
    SMALL_DESKTOP: "SMALL_DESKTOP",
    MOBILE: "MOBILE",
};

export const getCurrentBrand = () => process.env.REACT_APP_CURRENT_BRAND || Brands.SXM_MEDIA;

export const isEven = (num) => num % 2 === 0;

export const isOdd = (num) => num % 2 === 1;

// start: inclusive, end: exclusive
export const range = (end, start = 0) =>
    [...Array(end).keys()].filter((num) => num >= start);

export const useScroll = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        };

        window.addEventListener("scroll", updatePosition);
        updatePosition();

        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return {scrollPosition};
};

export const useWindow = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    let breakpoint = null;
    if (windowSize.width <= 768) {
        breakpoint = Breakpoints.MOBILE;
    } else if (windowSize.width <= 1368) {
        breakpoint = Breakpoints.SMALL_DESKTOP;
    } else {
        breakpoint = Breakpoints.DESKTOP;
    }

    return {windowSize, breakpoint};
};

export const wait = (seconds) => {
    return new Promise((res) => setTimeout(res, seconds * 1000));
};

export const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0});
};

export const scrollToSection = (allSectionRefs, selectedSection) => {
    window.scrollTo({top: allSectionRefs[selectedSection].current.offsetTop, left: 0});
};

export const sendFormSubmission = async (currentBrand, formData) => {
    const formInfo = {
        [Brands.SXM_MEDIA]: {
            formId: "2022TrendsReportSxmMedia",
            sheetName: "formSubmits",
            bodyName: "formSubmit",
        },
        [Brands.ADSWIZZ]: {
            formId: "2022TrendsReportAdsWizz",
            sheetName: "sheet1",
            bodyName: "sheet1",
        },
    };

    const {formId, sheetName, bodyName} = formInfo[currentBrand];

    await fetch(`https://api.sheety.co/da7b52963d543e726c160c192b964b16/${formId}/${sheetName}`, {
        method: "POST",
        body: JSON.stringify({[bodyName]: {...formData, dateSubmitted: new Date().toString()}}),
        headers: {"Content-Type":"application/json"},
    });
};

export const areCookiesAllowed =
    localStorage.getItem("useCookies") === "true" ||
    localStorage.getItem("useCookies") === true;
