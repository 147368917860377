import {useEffect, useRef, useState} from "react";
import {wait} from "../utils";

const animation = {
    start: {
        opacity: 0,
        transform: "translateY(20px)",
        transition: "0.5s transform, 0.5s opacity",
    },
    end: {
        opacity: 1,
        transform: "translateY(0px)",
    },
};

const hasReachedTrigger = (elementRef, windowHeight) => {
    const distFromWindowTop = elementRef?.current?.getBoundingClientRect()?.y;
    if (!windowHeight || !distFromWindowTop) {
        return false;
    }

    return distFromWindowTop < windowHeight * 0.75;
};

const checkTriggerReached = (ref, windowHeight, triggerReached, setTriggerReached) => {
    if (!triggerReached && hasReachedTrigger(ref, windowHeight)) {
        setTriggerReached(true);
    }
};

const triggerAnimation = (ref, waitSeconds, reset = false) => {
    const state = reset ? "start" : "end";

    if (!ref?.current) {
        return;
    }

    window.requestAnimationFrame(async () => {
        if (waitSeconds > 0 && !reset) {
            await wait(waitSeconds);
        }
        Object.keys(animation[state]).forEach((style) => {
            ref.current.style[style] = animation[state][style];
        });
    });
};

export const FadeInSlideUp = ({
    children,
    className,
    scrollPos,
    windowSize,
    waitSeconds = 0,
}) => {
    const ref = useRef();
    const [isTriggered, setTriggered] = useState(false);

    // animations start with the element being initially hidden. since we need
    // to support users who have JavaScript disabled on their browser, the
    // element starts off visible until the page has been loaded, at which point
    // it resets back to the start state (where the element is hidden).
    useEffect(() => {
        triggerAnimation(ref, waitSeconds, true);
    }, []);

    useEffect(() => {
        if (!!scrollPos && windowSize) {
            checkTriggerReached(ref, windowSize.height, isTriggered, setTriggered);
        }
    }, [scrollPos, windowSize]);

    useEffect(() => {
        if (isTriggered) {
            triggerAnimation(ref, waitSeconds);
        }
    }, [isTriggered]);

    return (
        <div
            className={className}
            ref={ref}
            style={{opacity: 1, transform: "translateY(0px)"}}
        >
            {children}
        </div>
    );
};
