import {useEffect, useState} from "react";

import {scrollToSection, Sections} from "../utils";

import "../styles/Sidebar.css";

const sections = [
    Sections.SECTION_01, Sections.SECTION_02,
    Sections.SECTION_03, Sections.SECTION_04,
];
const sectionTitles = {
    [Sections.SECTION_01]: "The New New in Listening",
    [Sections.SECTION_02]: "The New New in Creating",
    [Sections.SECTION_03]: "The New New in Advertising",
    [Sections.SECTION_04]: "The New New in Opportunity",
};

export const Sidebar = ({allSectionRefs, currentSection, hide}) => {
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        if (currentSection.name !== activeSection) {
            setActiveSection(
                sections.includes(currentSection.name)
                    ? currentSection.name
                    : null
            );
        }
    }, [currentSection]);

    return (
        <div
            className="sidebar"
            style={{
                visibility: hide ? "hidden": "visible",
                opacity: hide ? 0 : 1,
            }}
        >
            <div className="container">
                <div className="sections">
                    {sections.map((section, index) => {
                        const isActive = activeSection === section;
                        return (
                            <div
                                className={`section ${isActive ? "active" : "inactive"}`}
                                onClick={() => {
                                    if (!isActive && activeSection !== null) {
                                        scrollToSection(allSectionRefs, section);
                                    }
                                }}
                                key={index}
                            >
                                {isActive && (
                                    <div className="section-title gt-america-medium-16-24">
                                        {sectionTitles[section]}
                                    </div>
                                )}
                                <div className="square">
                                    <div className="inner-square"></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
